import React,{useEffect, useState} from 'react'

import { Wrapper, SearchBox, Popular, SelectBox, BottomBox } from "./model3.style";

import Section from "../../components/Section1";
import Interior from "../../components/Section2";
import Specs from "../../components/Section3";
import Header from "../Header";
import Footer from "../Footer";


import Popup from '../../components/Popup/Popup'

import '../../baseStyle.css'

const Support = ({language, setLanguage}) =>{
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    let [searchVal, setSearchVal] = useState('')
    
    let [selectData, setSelectData] = useState([{
        title: '汽车充电和功能',
        list: ['超级充电', '软件更新', '自动驾驶仪', '升级', '超级充电', '汽车安全功能']
    },{
        title: '汽车充电和功能',
        list: ['超级充电', '软件更新', '自动驾驶仪', '升级', '超级充电', '汽车安全功能']
    },{
        title: '汽车充电和功能',
        list: ['超级充电', '软件更新', '自动驾驶仪', '升级', '超级充电', '汽车安全功能']
    }])

    let [selectDetail, setSelectDetail] = useState([{
        title: '视频指南',
        content: '安全性是 Model 3 整体设计的重中之重。车身架构采用钢铝混合金属材质，保证各部位的支撑强度，在一次内部翻滚测试中，Model 3 在配置全景玻璃车顶的情况下，仍成功抵御了四倍于其自身质量的重压，大约相当于两只成年非洲象的重量。',
        link: '',
        img: 'Support-1',
    },{
        title: '视频指南',
        content: '安全性是 Model 3 整体设计的重中之重。车身架构采用钢铝混合金属材质，保证各部位的支撑强度，在一次内部翻滚测试中，Model 3 在配置全景玻璃车顶的情况下，仍成功抵御了四倍于其自身质量的重压，大约相当于两只成年非洲象的重量。',
        link: '',
        img: 'Support-2',
    },{
        title: '视频指南',
        content: '安全性是 Model 3 整体设计的重中之重。车身架构采用钢铝混合金属材质，保证各部位的支撑强度，在一次内部翻滚测试中，Model 3 在配置全景玻璃车顶的情况下，仍成功抵御了四倍于其自身质量的重压，大约相当于两只成年非洲象的重量。',
        link: '',
        img: 'Support-3',
    }])

    

    return(
        <Wrapper>
            <Header
                setPopupType={setPopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            <SearchBox bg={'support-bg'}>
                <div className="search-Box">
                    <img src={'/images/search.png'} alt=""/>
                    <input value={searchVal} placeholder="搜索支持" onChange={()=>{}}/>
                </div>
            </SearchBox>

            <Popular>
                <div className="title">热门话题</div>
                <div className="Popular-list">
                    <div>租赁</div>
                    <div>超级充电</div>
                    <div>能量路由器</div>
                    <div>储能方案</div>
                    <div>专业服务</div>
                    <div>关于大龙新能源</div>
                </div>
            </Popular>

            <SelectBox>
                <div className="top-select">
                    <div>汽车</div>
                    <div>能量</div>
                </div>
                
                <div className="content-box">
                    {selectData.map((e,i)=>{
                        return (
                            <div className="list" key={i}>
                                <div className="list-title">{e.title}</div>
                                {e.list.map((E,I)=>{
                                    return (<div key={I}>{E}</div>)
                                })}
                            </div>
                        )
                    })}
                </div>
            </SelectBox>

            <Specs selectDetail={selectDetail}/>

            <BottomBox>
                <div style={{textAlign:"center"}}>
                    <div className="title">找不到你要的东西？</div>
                    <div className="desc">安全性是 Model 3 整体设计的重中之重。车身架构采用钢铝混合金属材质，保证各部位的支撑强度，在一次内部翻滚测试</div>
                </div>
                <button className="w190 btn-black">加入我们</button>
            </BottomBox>

            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
    )
}

export default Support;