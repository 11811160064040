import React, { useEffect, useState } from "react";

import { Wrapper, Content } from "./footer.syle";
import { auto } from "@popperjs/core";
import "../../baseStyle.css";

const Footer = ({ bottom, language, setLanguage }) => {
  const [state, setState] = useState(false);
  const [popupState, setPopupState] = useState(false);

  const data = [
    "大龙新能源网站尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，大龙新能源网站会按照本隐私权政策的规定使用和披露您的个人信息。但大龙新能源网站将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，大龙新能源网站不会将这些信息对外披露或向第三方提供。大龙新能源网站会不时更新本隐私权政策。您在同意大龙新能源网站服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于大龙新能源网站服务使用协议不可分割的一部分。",
    "1.适用范围",
    "a)  在您使用网站提供的网络服务，或访问大龙新能源网站平台网页时，大龙新能源网站会自动接收并记录的您的浏览器和计算机上的部分信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；",
    "b) 大龙新能源网站通过合法途径从商业伙伴处取得的用户个人数据。",
    "您了解并同意，以下信息不适用本隐私权政策：",
    "a) 您在使用大龙新能源网站平台提供的搜索服务时输入的关键字信息；",
    "b) 大龙新能源网站收集到的您在本网站发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；",
    "c) 违反法律规定或违反本网站规则行为及本网站已对您采取的措施的信息。",
    "2.信息使用",
    "a) 大龙新能源网站不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和大龙新能源网站（含本网站关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。",
    "b) 大龙新能源网站亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何大龙新能源 网站平台用户如从事上述活动，一经发现，大龙新能源网站有权立即终止与该用户的服务协议。",
    "c) 为服务用户的目的，大龙新能源网站可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与大龙新能源网站合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。",
    "3.信息披露",
    "在如下情况下，大龙新能源网站将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：",
    "a) 经您事先同意，向第三方披露；",
    "b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；",
    "c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；",
    "d) 如您出现违反中国有关法律、法规或者大龙新能源网站服务协议或相关规则的情况，需要向第三方披露；",
    "e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；",
    "f) 在大龙新能源网站平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，大龙新能源网站有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。",
    "g) 其它X网站根据法律、法规或者网站政策认为合适的披露。",
    "4. 信息存储和交换",
    "大龙新能源 网站收集的有关您的信息和资料将保存在大龙新能源 网站及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或大龙新能源网站收集信息和资料所在地的境外并在境外被访问、存储和展示。",
    "5.信息安全申明",
    "a) 大龙新能源 网站具备有个人信息安全保护功能，大龙新能源 网站将通过进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。",
    "b) 在使用大龙新能源网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络大龙新能源网站客服，以便大龙新能源网站采取相应措施。",
  ];

  const resizeUpdate = (ev) => {
    console.log(ev.target.innerWidth);
    if (ev.target.innerWidth >= 600) {
      setState(true);
    } else {
      setState(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 600) {
      setState(true);
    } else {
      setState(false);
    }
    window.addEventListener("resize", resizeUpdate);
    return () => {
      window.removeEventListener("resize", resizeUpdate);
    };
  }, []);

  return (
    <Wrapper>
      {state ? (
        <Content className={bottom}>
          <div>
            <a href="" target="_blank">
              大龙新能源（深圳）有限公司
            </a>
          </div>
          <div 
            // style={{display: 'flex', flexDirection: 'row'}}
          > 
            {/* <div style={{margin:'0 auto'}}>
              <a
                target="_blank"
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011202000831"
                style={{display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}}
              >
                <img src="/images/备案图标.png" style={{float: 'left'}}/>
                <p style={{float:"left",height:'20px',lineHeight:'20px',margin: '0px 0px 0px 5px', color:'#939393'}}>
                  苏公网安备 32011202000831号
                </p>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                    苏ICP备2023015853号-2
                </a>
              </a>
            </div> */}
            <a href="" target="_blank">
              {language? '联系方式':'Service Email：'}
              contact@dalong.com
            </a>
            <a onClick={() => setPopupState(true)}>隐私和法律</a>
            {/* <a href="" target="_blank" >营业执照</a>
                        <a href="" target="_blank" >隐私和法律</a>
                        <a href="" target="_blank" >联系我们</a>
                        <a href="" target="_blank" >工作机会</a>
                        <a href="" target="_blank" >最新消息</a> */}
          </div>
        </Content>
      ) : (
        <Content className={bottom}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <a href="" target="_blank">
              大龙新能源（深圳）有限公司
            </a>
            {/* <div style={{display: 'flex', flexDirection: 'column'}}> 
            <a>
              <a
                target="_blank"
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011202000831"
              >
                <img src="/images/备案图标.png" style={{marginRight: '3px', marginTop: '', width: '12px'}}/>
                  苏公网安备 32011202000831号
              </a>
            </a>
            </div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                苏ICP备2023015853号-2
            </a> */}
            <a href="" target="_blank">
              {language? '联系方式':'Service Email：'}
              contact@dalong.com
            </a>
            <a onClick={() => setPopupState(true)}>隐私和法律</a>
            {/* <a href="" target="_blank" >营业执照</a>
                        <a href="" target="_blank" >隐私和法律</a>
                        <a href="" target="_blank" >联系我们</a>
                        <a href="" target="_blank" >工作机会</a>
                        <a href="" target="_blank" >最新消息</a> */}
          </div>
        </Content>
      )}

      {popupState ? (
        <div className="mask">
          <div className="Popup">
            <h3 style={{ textAlign: "center", marginBottom: 20 }}>
              大龙新能源网站隐私政策
            </h3>
            <div
              className="pop-content"
              style={{ height: "80%", overflowY: "scroll" }}
            >
              {data.map((e, i) => {
                return (
                  <div
                    key={i}
                    style={{ textIndent: 35, fontSize: 14, marginTop: 10 }}
                  >
                    {e}
                  </div>
                );
              })}
            </div>
            <div
              className="w220 btn-black"
              style={{
                margin: "20px auto",
                textAlign: "center",
                lineHeight: "36px",
              }}
              onClick={(ev) => setPopupState(false)}
            >
              关 闭
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default Footer;
