import React,{useEffect, useState} from 'react'

import { Wrapper, Content } from "./modelx.style";

import Section from "../../components/Section1";
import BaseComponent from "../../components/Section4";
import Specs from "../../components/Section3";
import Header from "../Header";
import Footer from "../Footer";

import Popup from '../../components/Popup/Popup'


const EnergyRouter = ({language, setLanguage}) =>{
    document.title = "能源路由器";
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    const [listData, setListData] = useState([
        {
            title: '能源路由器',
            desc:"",
            backgroundImg: 'EnergyRouter-1',
            type: '1',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },
        {
            subTitle:"存储转发",
            title: '能源路由器',
            desc:"借鉴信息基础设施中的网络路由器原理，对能量进行存储转发，是能源互联网运行与控制的具体实施装置。能量路由器具有硬件通用化、软件定制化和即插即用等技术特点。既是 能量管理控制器又是能量计量的终端，同时可以跟数据中心等信息基础设施有机结合，是信息能源基础设施一体化的核心装备。",
            backgroundImg: 'EnergyRouter-2',
            type: '6',
            leftbtn:"了解更多",
            rightbtn:"联络我们",
        },
        {
            subTitle:"快速组建",
            title: '即插即用，扩展性强',
            desc:"能量路由器预留不同能量模块接口，即插即用。根据不同模块性能快速组建微电网。微电网组建完毕后具有：电网无需扩容，节约投资成本。削峰填谷，降低用电成本。能源高效利用，降低城市电网负荷压力等功能。",
            backgroundImg: 'EnergyRouter-3',
            type: '6',
            leftbtn:"了解更多",
            rightbtn:"联络我们",
        },
        {
            subTitle:"零碳智慧工厂",
            title: '能量转化，应用场景广泛',
            desc:"能量路由器可以将多种能源（如光能、风能、水能等）所产生的能量转化为电能，实现 不同能源载体的输入、输出、转换、存储，达到不同能源形式的互联互补、生产与消费环节 的有机贯通。主要应用于光伏、储能、风电、氢能、电动车、负荷、电网等多种能源互补就 地供电，服务场景包括绿色建筑、零碳园区、光储充电站、低压台区微电网、零碳智慧工厂等。",
            backgroundImg: 'EnergyRouter-4',
            type: '5',
            leftbtn:"了解更多",
            rightbtn:"联络我们",
        },
        {
            subTitle:"主动管理",
            title: '智能系统，安全可靠',
            desc:"智能协调控制算法，可根据网络运行状态以及用户和控制中心的指令，对各端口电压电 流进行实时控制，实现对电力网络能量流的主动管理。多能互补功率补偿技术在市电容量 不足的情况下可借用或共享第三方能源（储能、光伏等）达到设备最大功率输出上限。",
            backgroundImg: 'EnergyRouter-5',
            type: '4',
            leftbtn:"了解更多",
            rightbtn:"联络我们",
        },
        {
            subTitle:"模块化式",
            title: '快速交付',
            desc:"标准模块化式集装箱设计，缩短建设周期。",
            backgroundImg: 'EnergyRouter-6',
            type: '6',
            leftbtn:"了解更多",
            rightbtn:"联络我们",
        },
        {
            subTitle:"健康管理",
            title: '高效运维',
            desc:"设备健康管理，关键器件寿命预测，智能预警。 故障波纹，远程诊断，在线升级，快速处理。",
            backgroundImg: 'EnergyRouter-7',
            type: '5',
            leftbtn:"了解更多",
            rightbtn:"",
        },
        {
            subTitle:"利用可再生能源",
            title: '低碳环保、节能减排',
            desc:"利用可再生能源进行供电，不产生二氧化碳等污染物，降低了环境污染，减少了能源浪费，提升能源利用效率，实现节能减排。",
            backgroundImg: 'EnergyRouter-8',
            type: '6',
            leftbtn:"了解更多",
            rightbtn:"联络我们",
        },
    ])
    
    const [listEnData, setListEnData] = useState([
        {
            title: 'Energy router',
            desc:"",
            backgroundImg: 'EnergyRouter-1',
            type: '1',
            leftbtn:"Contact us",
            // rightbtn:"用户故事",
        },
        {
            subTitle:"Store-and-forward",
            title: 'Energy router',
            desc:"Drawing on the principles of network routers in information infrastructure, the energy router is capable of storing and forwarding energy and is a specific implementation device for the operation and control of the Energy Internet. The energy router features hardware standardization, software customization, and plug-and-play technology. It serves both as an energy management controller and an energy metering terminal. It can also be organically integrated with information infrastructures such as data centers, making it a core piece of equipment for the integration of information and energy infrastructures.",
            backgroundImg: 'EnergyRouter-2',
            type: '6',
            leftbtn:"Learn more",
            rightbtn:"Contact us",
        },
        {
            subTitle:"Rapid deployment",
            title: 'Plug-and-play with strong scalability',
            desc:"The energy router reserves interfaces for different energy modules, allowing for plug-and-play functionality. It enables the rapid assembly of a microgrid based on the performance of different modules. Once the microgrid is established, it offers several benefits: there is no need for grid expansion, saving investment costs; it achieves peak shaving and valley filling, reducing electricity costs; and it allows for the efficient use of energy, thereby reducing the load pressure on urban power grids.",
            backgroundImg: 'EnergyRouter-3',
            type: '6',
            leftbtn:"Learn more",
            rightbtn:"Contact us",
        },
        {
            subTitle:"Zero-Carbon Smart Factory",
            title: 'Energy conversion with a wide range of applications',
            desc:"The energy router is capable of converting energy from various sources (such as solar, wind, and hydro power) into electrical energy. It achieves the input, output, conversion, and storage of different energy carriers, facilitating the interconnection and complementarity of different forms of energy, and the organic integration of production and consumption phases. It is primarily used in photovoltaics, energy storage, wind power, hydrogen energy, electric vehicles, loads, and power grids for local complementary energy supply. The service scenarios include green buildings, zero-carbon parks, photovoltaic storage charging stations, low-voltage district microgrids, zero-carbon smart factories, and more.",
            backgroundImg: 'EnergyRouter-4',
            type: '5',
            leftbtn:"Learn more",
            rightbtn:"Contact us",
        },
        {
            subTitle:"Proactive Management",
            title: 'Intelligent System, Safe and Reliable',
            desc:"The intelligent coordination control algorithm can real-time control the voltage and current of each port based on the network's operational status and the instructions from users and control centers, achieving proactive management of the energy flow in the electrical network. The multi-energy complementary power compensation technology can borrow or share third-party energy sources (such as energy storage, photovoltaics, etc.) when the utility power capacity is insufficient, to reach the maximum power output limit of the equipment.",
            backgroundImg: 'EnergyRouter-5',
            type: '4',
            leftbtn:"Learn more",
            rightbtn:"Contact us",
        },
        {
            subTitle:"Modular Design",
            title: 'Rapid Delivery',
            desc:"Standardized modular container design shortens the construction period.",
            backgroundImg: 'EnergyRouter-6',
            type: '6',
            leftbtn:"Learn more",
            rightbtn:"Contact us",
        },
        {
            subTitle:"Health Management",
            title: 'Efficient Operation and Maintenance',
            desc:"Equipment health management, key component lifespan prediction, and intelligent early warning. Fault ripple effect, remote diagnostics, online upgrades, and rapid resolution.",
            backgroundImg: 'EnergyRouter-7',
            type: '5',
            leftbtn:"Learn more",
            rightbtn:"",
        },
        {
            subTitle:"Utilizing Renewable Energy",
            title: 'Low-Carbon and Environmentally Friendly, Energy-Saving and Emission Reduction',
            desc:"Power supply utilizing renewable energy does not generate pollutants such as carbon dioxide, thereby reducing environmental pollution, minimizing energy waste, enhancing energy utilization efficiency, and achieving energy conservation and emission reduction.",
            backgroundImg: 'EnergyRouter-8',
            type: '6',
            leftbtn:"Learn more",
            rightbtn:"Contact us",
        },
    ])

    const [renderData, setRenderData] = useState(language? listData:listEnData)


    useEffect(()=>{
        if(language){
            document.title = "能源路由器";
            setRenderData(()=> listData)
        }else{
            document.title = "Energy router";
            setRenderData(()=> listEnData)
        }
    },[language])
    
		
    useEffect(()=>{
        document.querySelector('.page-content').scrollTop = 0
    },[renderData])
    return(
        <Wrapper className="page-content">
            <Header
                setPopupType={setPopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            
            {renderData.map((e,i)=>{
                if(e.type === '1'){
                    return (
                        <Content key={i}>
                            <Section 
                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                range={e.range}
                                speed={e.speed}
                                top={e.top}

                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}

                                setPopupType={setPopupType}
                                setOpen={setOpen}

                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                }else if(e.type === '4'||e.type === '5'||e.type === '6'){
                    return (
                        <Content key={i}>
                            <BaseComponent 
                                subTitle={e.subTitle}
                                type={e.type}

                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                

                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}

                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                }
            })}

            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />
            
            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
    )
}

export default EnergyRouter;