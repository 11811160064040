import React,{ useEffect, useState } from 'react';
import { HashRouter as Router ,Routes, Route, Navigate } from 'react-router-dom';

// Using AOS for animation

// Components
import GlobalStyle from './globalstyles';
import Home from './pages/home';
import PowerBank from './pages/PowerBank';
import Service from './pages/Service';
import EnergyRouter from './pages/EnergyRouter';
import EnergyStorageSolution from './pages/EnergyStorageSolution';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Account from './pages/Account';

import { useSelector, useDispatch } from 'react-redux';
import { selectUser, login, logout } from './features/userSlice';
import { auth } from './pages/firebase';
import About from './pages/About';
import NewAbout from './pages/NewAbout';
import Support from './pages/Support';

function App() {

  const user = useSelector(selectUser)

  const dispatch = useDispatch()


  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        // User is signed in
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
          })
        )
      } else {
        // User is signed out
        dispatch(logout())
      }
    })
  }, [dispatch])

  const [language, setLanguage] = useState(true);
  // 判断用户是否为海外ip
  useEffect(()=>{
    let userLanguage = navigator.language || navigator.userLanguage; 
    console.log(userLanguage)
    if(userLanguage === 'zh-CN'){
      setLanguage(true)
    }else{
      setLanguage(false)
    }
  },[])

  useEffect(()=>{
    console.log(window.scrollY)
  })

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home language={language} setLanguage={setLanguage}/>}/>
          <Route path="/power-bank" element={<PowerBank language={language} setLanguage={setLanguage}/>} />
          <Route path="/energy-router" element={<EnergyRouter language={language} setLanguage={setLanguage}/>} />
          <Route path="/energy-storage-solution" element={<EnergyStorageSolution language={language} setLanguage={setLanguage}/>} />
          <Route path="/service" element={<Service language={language} setLanguage={setLanguage}/>} />
          <Route path="/signup" element={<Signup language={language} setLanguage={setLanguage}/>} />
          <Route 
            path="/dalongaccount" 
            element={
              <main>
                {user ? <Account/> : <Navigate to='/login'/>}
              </main>
            }
          />
          <Route 
            path="/login" 
            element={
              <main>
                {user ? <Navigate to='/dalongaccount'/> : <Login/>}
              </main>
            } 
          />
          <Route path="/NewAbout" element={<NewAbout language={language} setLanguage={setLanguage}/>} />
          <Route path="/support" element={<Support language={language} setLanguage={setLanguage}/>} />
        </Routes>
        <GlobalStyle/>
      </div>
    </Router>
  );
}

export default App;