import React, { useEffect, useState } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";
import '../../baseStyle.css'

import { Wrapper, Content } from "./baseComponent.style";


// type: "4"-左   "5"-右   "6"-下
const BaseComponent = ({
	subTitle,
	title,
	desc,
	backgroundImg,
	bgData,
	type,
	leftbtn,
	rightbtn,

	setPopupType,
	setOpen,

	language,
	setLanguage,
}) => {
	const [state, setState] = useState(false)

	const resizeUpdate = (ev) => {
		console.log(ev.target.innerWidth)
		if (ev.target.innerWidth >= 600) {
			setState(true)
		} else {
			setState(false)
		}
	}

	useEffect(() => {
		AOS.init({ duration: 1400 });

		if (window.innerWidth >= 600) {
			setState(true)
		} else {
			setState(false)
		}
		window.addEventListener('resize', resizeUpdate)
		return () => {
			window.removeEventListener('resize', resizeUpdate)
		}
	}, []);


	const btn_Fn = (type) => {
		if (type === '联络我们' || type === '用户故事') {
			setPopupType(type)
			setOpen(true)
		}
	}

	return (
		<>
			{state ?
				<Wrapper
					style={
						type === '4' ?
							{ flexDirection: 'row' } :
							type === '5' ?
								{ flexDirection: 'row-reverse' } :
								{ flexDirection: 'column-reverse' }
					}
				>
					{type === '4' || type === '5' ?
						<div className="detail">
							<div>
								<div className="subTitle">{subTitle}</div>
								<div className="title">{title}</div>
								<div className="desc">{desc}</div>
							</div>
							<div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
								<button className="w190 btn-black" onClick={() => btn_Fn(leftbtn)}>{leftbtn}</button>
								{/* {leftbtn && 
                                <div className="addIcon-btn" onClick={()=> btn_Fn(leftbtn)}>
                                    <img src={'/images/add.png'} loading="lazy" alt="" /> {leftbtn} 
                                </div>
                            } */}

								{/* {rightbtn && 
                                <button className='w190 btn-black' style={{marginLeft:20}} onClick={()=> btn_Fn(rightbtn)}>{rightbtn}</button>
                            } */}

							</div>
						</div>
						:
						<div className="detail-bottom">
							<div 
								style={language? 
									{ width: 300, marginRight: 130 }:
									title.length>60? { width: 1250, marginRight: 30 }:{ width: 600, marginRight: 30 }
								}
							>
								<div className="subTitle">{subTitle}</div>
								<div className="title">{title}</div>
								<div style={{ marginTop: 30, display: "flex", alignItems: "center" }}>
									<button className="w190 btn-black" onClick={() => btn_Fn(leftbtn)}>{leftbtn}</button>
									{/* <div className="addIcon-btn"  onClick={()=> btn_Fn(leftbtn)}>
                                    <img src={'/images/add.png'} loading="lazy" /> {leftbtn} 
                                </div>
                                <button className='w190 btn-black' style={{marginLeft:20}}  onClick={()=> btn_Fn(rightbtn)}>{rightbtn}</button> */}
								</div>
							</div>
							<div className="desc">{desc}</div>
						</div>
					}

					<Content
						bg={backgroundImg}
						style={type === '4' || type === '5' ? {} : { width: '100%', height: '100%' }}
					>
						{bgData && <div className="bg-list">
							{bgData.map((e, i) => {
								return (
									<div className="item" key={i}>
										<div className="item-title">{e.title}</div>
										<div>{e.text}</div>
									</div>
								)
							})}
						</div>}
					</Content>
				</Wrapper>
				:
				<Wrapper
					style={{ flexDirection: 'column-reverse' }}
				>

					<div className="detail-bottom-phone" style={{ padding: 20 }}>
						<div>
							<div className="subTitle">{subTitle}</div>
							<div className="title">{title}</div>
							<div className="desc" style={{ marginTop: 20 }}>{desc}</div>
						</div>
						<div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
							<button className="w190 btn-black" onClick={() => btn_Fn(leftbtn)}>{leftbtn}</button>
							{/* {leftbtn? 
                            <div className="addIcon-btn" onClick={()=> btn_Fn(leftbtn)}>
                                <img src={'/images/add.png'} loading="lazy" /> {leftbtn} 
                            </div>
                            :
                            ''
                        } */}
							{/* {rightbtn? 
                            <button className='w190 btn-black' style={{marginLeft:20}} onClick={()=> btn_Fn(rightbtn)}>{rightbtn}</button>
                            :
                            ''
                        } */}
						</div>
					</div>

					<Content
						bg={backgroundImg}
						style={{ width: '100%', height: '100%' }}
					>
						{bgData && <div className="bg-list" style={{ top: 30 }}>
							{bgData.map((e, i) => {
								return (
									<div className="item" key={i} style={{ marginBottom: 30 }}>
										<div className="item-title">{e.title}</div>
										<div>{e.text}</div>
									</div>
								)
							})}
						</div>}
					</Content>
				</Wrapper>
			}
		</>

	)
}

export default BaseComponent;