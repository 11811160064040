import styled from "styled-components";

export const Wrapper = styled.div`
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }



    @media screen and (max-width: 600px){
        
    }
`;
export const Content = styled.div`
    width: 100vw;
    // height: 100vh;
    display: flex;

    .about-bg-title{
        color:#fff;
        font-size: 36px;
        padding: 50px;
        // position:fixed;
        // top:50%;
        // left:5vw;
    }
    .about-detail{
        width: 40vw;
        padding: 120px;
        .detail-item{
            margin-bottom: 60px;

            font-size: 12px;
            line-height: 24px;
            white-space: pre-wrap;
            
            .title{
                font-size: 16px;
                line-height: 22px;
                font-weight: 900;
            }
            .desc{
                font-size: 26px;
                line-height: 37px;
                margin-bottom: 20px;
                font-weight: 900;
            }
            .listMap{
                .listMap-item{
                    margin-bottom: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 810px){
        flex-wrap: wrap;
        .about-bg-title{
            position:none;
        }
        .about-detail{
            width: 100vw;
            padding: 40px 20px;
            .detail-item{
                margin-bottom: 40px;

                .listMap{
                    .listMap-item{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`;

export const AboutImg = styled.div`
    width: 100vw;
    min-height: 400px;
    // height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:  ${props => `url('/images/second-about.png'), url('/images/second-about-small.webp')`};/url is by default in public section;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`

