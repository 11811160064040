import React,{useEffect, useState} from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import LazyLoad from 'react-lazyload';
import { Wrapper, ContentMid } from "./interior.style";

const Interior = ({bg, leftbtn, rightbtn, title, setPopupType, setOpen}) =>{

    const [state, setState] = useState(false)

    const resizeUpdate = (ev) =>{
        console.log(ev.target.innerWidth)
        if(ev.target.innerWidth >= 600){
            setState(true)
        }else{
            setState(false)
        }
    }

    useEffect(() => {
        AOS.init({duration: 1400});

        if(window.innerWidth >= 600){
            setState(true)
        }else{
            setState(false)
        }
        window.addEventListener('resize', resizeUpdate)
        return ()=>{
            window.removeEventListener('resize', resizeUpdate)
        }
    }, []);

    const btn_Fn = (type) =>{
        if(type === '联络我们'||type === '用户故事'){
            setPopupType(type)
            setOpen(true)
        }
    }

    return(
        <>
            {state? 
                <Wrapper bg={bg}>
                    <div className="title" >
                        <h1>{title}</h1>
                    </div>

                    <ContentMid>
                        {leftbtn &&
                            <button className='w220 btn-black' onClick={()=> btn_Fn(leftbtn)}>{leftbtn}</button>
                        }
                        {rightbtn &&
                            <button className='w220 btn-black-vague' style={{ marginLeft: 20 }} onClick={()=> btn_Fn(rightbtn)}>{rightbtn}</button>
                        }
                    </ContentMid>
                </Wrapper>
                :
                <Wrapper 
                    bg={bg} 
                    style={{
                        display: "flex",     
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <div className="title" style={{width: '100%', marginBottom: 40}}>
                        <h1 style={{margin: 0}}>{title}</h1>
                    </div>
                    <ContentMid style={{width: '100%', margin: 0,alignItems: 'center'}}>
                        {leftbtn &&
                            <button className='w220 btn-black' onClick={()=> btn_Fn(leftbtn)}>{leftbtn}</button>
                        }
                        {rightbtn &&
                            <button className='w220 btn-black-vague' style={{ marginTop: 20 }} onClick={()=> btn_Fn(rightbtn)}>{rightbtn}</button>
                        }
                    </ContentMid>
                </Wrapper>
            }
        </>
    )
}

export default Interior;