import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100vw;
    padding: 60px 0;
    // margin-bottom: 40px;
    // background: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  space-between;

    scroll-snap-align: center;

    .title{
        font-size:18px;
        line-height: 38px;
        font-weight: 900;
        padding: 0 20px;
    }
    .subTitle{
        font-size:16px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom:10px;
    }
    .desc{
        font-size:16px;
        line-height: 20px;
        margin-bottom: 20px;
    }
`;