import React,{ useEffect, useState } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";


import { Wrapper,Content, ContentPhone } from "./spec.style";

const Specs = ({selectDetail}) =>{
    
    const [state, setState] = useState(false)

    const resizeUpdate = (ev) =>{
        console.log(ev.target.innerWidth)
        if(ev.target.innerWidth >= 600){
            setState(true)
        }else{
            setState(false)
        }
    }

    useEffect(() => {
        AOS.init({duration: 1400});
        AOS.refresh();

        if(window.innerWidth >= 600){
            setState(true)
        }else{
            setState(false)
        }
        window.addEventListener('resize', resizeUpdate)
        return ()=>{
            window.removeEventListener('resize', resizeUpdate)
        }
    }, []);

    return(
        // <Wrapper>
        //     <Content>
        //         {selectDetail && selectDetail.map((e,i)=>{
        //             return (
        //                 <div className="row" key={i}>
        //                     <div 
        //                         className="col"  
        //                         // data-aos={'fade-right'}
        //                         data-aos={i%2===0? 'fade-right':'fade-left'}
        //                     > 
        //                         <img src={'/images/'+e.img} alt="game"/>
        //                     </div>
        //                     <div 
        //                         className="col text" 
        //                         // data-aos={'fade-right'}
        //                         data-aos={i%2===0? 'fade-left':'fade-right'}
        //                     >
        //                         <h3>{e.title}</h3>
        //                         <p>{e.content}</p>
        //                         <p>查看视频</p>
        //                     </div>
        //                 </div>
        //             )
        //         })}
                
        //     </Content>
        // </Wrapper>
        <Wrapper>
            {state? 
                <Content>
                    {selectDetail && selectDetail.map((e,i)=>{
                        return (
                            <div className="row" key={i}>
                                <div 
                                    className="col"  
                                    // data-aos={'fade-right'}
                                    data-aos={i%2===0? 'fade-right':'fade-left'}
                                > 
                                    <img src={'/images/'+e.img + '.png'} alt="game" loading="lazy"/>
                                </div>
                                <div 
                                    className="col text" 
                                    // data-aos={'fade-right'}
                                    data-aos={i%2===0? 'fade-left':'fade-right'}
                                >
                                    <h3>{e.title}</h3>
                                    <p>{e.content}</p>
                                    <p>查看视频</p>
                                </div>
                            </div>
                        )
                    })}
                    
                </Content>
                :
                <ContentPhone>
                 {selectDetail && selectDetail.map((e,i)=>{
                    return (
                        <div data-aos={'fade-top'} key={i}>
                            <div> 
                                <img src={'/images/'+ e.img + '.png'} alt="game" loading="lazy"/>
                            </div>
                            <div className="text">
                                <h3>{e.title}</h3>
                                <p>{e.content}</p>
                                <p style={{textDecoration:'underline'}}>查看视频</p>
                            </div>
                        </div>
                    )
                })} </ContentPhone>
            }
        </Wrapper>
    )
}

export default Specs;