import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: -35px;
    padding: 30px 0;
    background-color: #fff;
    
    scroll-snap-align: center;

    .bottom{
        a{
            transition: color 200ms ease-in;
            :hover{
                color: #000;
                cursor: pointer;
            }
        }
        span{    
            text-decoration: none;
            font-size: 0.85rem;
            font-weight: 500;
            padding: 5px 18px;
            letter-spacing: 0.4px;
            color: rgba(59, 59, 59, 0.753);
            transition: color 200ms ease-in;
            :hover{
                color: #000;
            }
        }
    }

    .mask{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.5);
        .Popup{
            width: 600px;
            height: 80%;
            background: #fff;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            padding: 20px;
            z-index: 111;
        }
        .pop-content::-webkit-scrollbar { width: 0 !important }
        .pop-content { -ms-overflow-style: none; }
        .pop-content { overflow: -moz-scrollbars-none; }
    }

    @media screen and (max-width: 420px){
        // margin-top: -45px;
        padding: 10px 0;
        .mask{
            .Popup{
                width: 100vw;
                height: 85%;
            }
        }
    }

`;

export const Content = styled.div`

    display: flex;
    flex-wrap: wrap;

    a{
        text-decoration: none;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 5px 18px;
        letter-spacing: 0.4px;
        color: rgba(59, 59, 59, 0.753);
        transition: color 200ms ease-in;
        :hover{
            color: black;
        }
    }

    @media screen and (max-width: 430px) {
        flex-direction: column;
        align-items: center;
    }
`;