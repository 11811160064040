import React,{useEffect, useState} from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import { Wrapper, Content, ItemBg } from "./story.style";

const Story = () =>{
    let data = {
        img:'/images/sotry-1.png',
        title: '比预想的更快',
        text: `无需更多投资，即刻引领分钟级超充时代，大龙 10kV 预装式智慧超充站 HV-UFC 超 高电压平台，单枪最大电流达 600A，可在 5 分钟内完成 50%-80%的续航补充，满足乘
        用、商用、工程、重卡等电车极速补能场景，同时也兼容当下普通快充需求，性 能卓越，缩短更多补能时间，缓解里程焦虑。无需更多投资，即刻引领分钟级超充时代，大龙 10kV 预装式智慧
        超充站 HV-UFC 超 高电压平台，单枪最大电流达 600A，可在 5 分钟内完成 50%-80%的续航补充，满足乘用、商用、工程、重卡等电车极速补能场景，同时也兼容当下普通
        快充需求，性 能卓越，缩短更多补能时间，缓解里程焦虑。无需更多投资，即刻引领分钟级超充时代，大龙 10kV 预装式智慧超充站 HV-UFC 超 高电压平台，单枪最大电流达 600A，可在 5 
        分钟内完成 50%-80%的续航补充，满足乘用、商用、工程、重卡等电车极速补能场景，同时也兼容当下普通快充需求，性 能卓越，缩短更多补能时间，缓解里程焦虑。无需更
        多投资，即刻引领分钟级超充时代，大龙 10kV 预装式智慧超充站 HV-UFC 超 高电压平台，单枪最大电流达 600A，可在 5 分钟内完成 50%-80%的续航补充，满足乘用、商
        用、工程、重卡等电车极速补能场景，同时也兼容当下普通快充需求，性 能卓越，缩短更多补能时间，缓解里程焦虑。无需更多投资，即刻引领分钟级超充时代，大龙 10kV 预装式智慧超充站 
        HV-UFC 超 高电压平台，单枪最大电流达 600A，可在 5 分钟内完成 50%-80%的续航补充，满足乘用、商用、工程、重卡等电车极速补能场景，同时也兼容当下普通快充需
        求，性 能卓越，缩短更多补能时间，缓解里程焦虑。`,
    }
    let [dataList, setDataList] = useState([data,data])

    let [IW, setIW] = useState(0)

    let [index, setIndex] = useState(0)
    let [moveState, setMoveState] = useState(false)
    let [X_obj, setX_obj] = useState({
        oldX: 0,
        newX: 0,
    })

    const resizeUpdate = (ev) =>{
        console.log(ev.target.innerWidth)
        if(ev.target.innerWidth >= 600){
            setIW(600)
        }else{
            setIW(ev.target.innerWidth)
        }
    }
    
    useEffect(() => {
        AOS.init({duration: 1400});

        if(window.innerWidth >= 600){
            setIW(600)
        }else{
            setIW(window.innerWidth)
        }
        window.addEventListener('resize', resizeUpdate)
        return ()=>{
            window.removeEventListener('resize', resizeUpdate)
        }
    }, []);

    let downFn = (ev) =>{
        // console.log('down',ev.pageX)
        ev.preventDefault()
        setMoveState(true)
        let copyObj = JSON.parse(JSON.stringify(X_obj))
        copyObj.oldX = ev.pageX
        setX_obj(()=> copyObj)
    }
    let moveFn = (ev) =>{
        if(moveState){
            // console.log('move',ev.pageX)
            console.log('moveState', moveState)
            console.log('X_obj', X_obj.oldX-X_obj.newX)
            ev.preventDefault()
            let copyObj = JSON.parse(JSON.stringify(X_obj))
            copyObj.newX = ev.pageX
            setX_obj(()=> copyObj)
        }
    }
    let upFn = (ev) =>{
        // console.log('up', ev.pageX)
        ev.preventDefault()
        if(X_obj.oldX-X_obj.newX >0){
            console.log('往右翻', dataList.length-1, index+1)
            if(dataList.length-1 >= index+1){
                setIndex(index+1)
            }
        }else if(X_obj.oldX-X_obj.newX <0){
            console.log('往左翻', 0, index-1)
            if(0 <= index-1){
                setIndex(index-1)
            }
        }
        setMoveState(false)
        
        setX_obj({
            oldX: 0,
            newX: 0,
        })
    }

    return(
        <Wrapper length={dataList.length} index={index} IW={IW} X_obj={X_obj}>
            <div 
                className="content"
                onMouseDown={(ev)=> downFn(ev)}
                onMouseMove={(ev)=> moveFn(ev)}
                onMouseOut={(ev)=> upFn(ev)}
                onMouseUp={(ev)=> upFn(ev)}
            >
                <div className="content-list">
                    {dataList.map((e,i)=>{
                        return (
                            <div key={i} className="content-item">
                                <div className="content-img-box">
                                    <img className="content-img" src={e.img} alt='' loading="lazy"/>
                                </div>
                                <div className="title">{e.title}</div>
                                <div className="text">{e.text}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Wrapper>
    )
}

export default Story;