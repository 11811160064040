import styled from "styled-components";

export const Wrapper = styled.div`
    overflow-x: hidden;

    
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`;


export const SearchBox = styled.div`
    width: 100vw;
    height: 360px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:  ${props => `url('/images/${props.bg}.png'), url('/images/${props.bg}-small.webp')`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .search-Box{
        max-width: 630px;
        width: 90%;
        height: 40px;
        padding: 5px 10px 5px 45px;
        border-radius: 4px;
        backdrop-filter: blur(30px);
        background-color: rgba(255, 255, 255, 0.3);
        position: relative;
        display: flex;

        img{
            min-width: 20px;
            height:20px;
            position: absolute;
            top: 10px;
            left: 15px;
        }
        input{
            width: 100%;
            height: 30px;
            line-height: 20px;
            border: 0;
            padding: 0;
            background: transparent;
            outline: 0;
            ::-webkit-input-placeholder{
                color:#fff;
            }
        }
    }
`;

export const Popular = styled.div`
    max-width: 1050px;
    width: 80%;
    margin: 60px auto 120px;
    .title{
        font-size: 26px;
        line-height: 38px;
        margin-bottom: 20px;
    }
    .Popular-list{
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div{
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 600px){
        margin: 40px auto 40px;
        .Popular-list{
            display: block;
        }
    }
`;

export const SelectBox = styled.div`
    max-width: 1050px;
    width: 80%;
    margin: 0 auto 60px;
    
    .top-select{
        padding-bottom: 20px;
        display: flex;
        border-bottom: 1px solid #000;
        div{
            width: 100%;
            text-align: center;
        }
    }
    .content-box{
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        .list{
            width: 150px;
            .list-title{
                font-weight: 900;
                font-size: 18px;
                line-height: 32px;
                margin-bottom: 20px;
            }
            div{
                margin-bottom: 12px;
            }
        }
    }
    @media screen and (max-width: 600px){
        .content-box{
            margin-top: 40px;
            display: block;
            .list{
                margin-bottom: 30px;
            }
        }
    }
`;

export const BottomBox = styled.div`
    width: 100vw;
    height: 250px;
    padding: 60px 0;
    // margin-bottom: 40px;
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  space-between;

    .title{
        font-size:26px;
        line-height: 38px;
        font-weight: 900;
    }
    .desc{
        font-size:12px;
        line-height: 20px;
        margin-top: 10px;
    }
`