import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100%;
    background: #000;
    display: flex;
    justify-content: center;


    .content{
        width: 100%;
        max-width: 600px;
        position: relative;
        overflow: hidden;

        .content-list{
            width: ${(props)=> `${props.length*props.IW}px`};
            display:flex;
            flex-wrap: nowrap;
            position: absolute;
            top: 0;
            left: ${(props)=> `-${props.index*props.IW+(props.X_obj.oldX - props.X_obj.newX)}px`};
            transition: 1s;

            .content-item{
                width: 100%;
                max-width: 600px;
                
                .content-img-box{
                    .content-img{
                        width: 100%;
                        max-width: 600px;
                        margin-top: 50px;
                    }
                }
                .title{
                    font-size: 26px;
                    line-height: 38px;
                    margin: 30px 0 20px 0;
                    color: #fff;
                }
                .text{
                    font-size: 12px;
                    line-height: 20px;
                    color: #fff;
                    padding: 0 2px;
                }
            }
        }
    }


    @media screen and (max-width: 600px){
        background: #fff;
        .content{
            .content-list{

                .content-item{

                    .content-img-box{
                        .content-img{
                            margin-top: 0px;
                        }
                    }
                    .title{
                        padding: 0 20px;
                        color: #000;
                    }
                    .text{
                        padding: 0 20px;
                        color: #000;
                    }
                }
            }
        }
    }
    
`;
