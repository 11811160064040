import React,{useEffect, useState} from 'react'

import { Wrapper, Content } from "./modely.style";

import Section from "../../components/Section1";
import Section2 from "../../components/Section2";
import BaseComponent from "../../components/Section4";
import Section5 from "../../components/Section5";
import Header from "../Header";
import Footer from "../Footer";

import Popup from '../../components/Popup/Popup'

const EnergyStorageSolution = ({language, setLanguage}) =>{
    document.title = "储能方案";
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    const [listData, setListData] = useState([{
            title: '储能方案',
            desc:"",
            backgroundImg: 'energy-storage-solution-1',
            range:'',
            speed:'',
            top:'',
            type: '1',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"可再生能源",
            title: '储能方案',
            desc:"光储充检解决方案基于清洁可再生能源接入、安全可靠的能量储能技术、高效的光伏发电技术、电动汽车大功率的超充技术、系统智能检测与管理技术，五大功能于一体的智能化 新型基础设施。通过五大功能的智能互动，形成电网、光储充检、一体化电站、电动汽车之 间协调运作模式，实现安全、经济、高效运行的清洁电力，助力碳中和。",
            backgroundImg: 'EnergyStorageSolution-2',
            type: '4',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"经济高效",
            title: '电网无需扩容，节约投资成本',
            desc:"电网无需扩容，节约投资成本 ，削峰填谷，降低用电成本；能源高效利用，降低城市电网负荷压力；具备调峰、调频、调压、抑制负荷波动、减少电力系统损耗；绿电交易，辅助服务，获取额外收益。",
            backgroundImg: 'EnergyStorageSolution-3',
            type: '6',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"安装便捷",
            title: '标准模块化设计',
            desc:"标准模块化设计，有效降低安装复杂性，缩短建设周期，实现快速安装。",
            backgroundImg: 'EnergyStorageSolution-4',
            type: '5',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"安全可靠",
            title: '全方位实时监控',
            desc:"全方位实时监控，实现对电力网络能量流的主动管理； 系统智能检测，支持自行设置调控规则，不同维度功能预警； 设备健康管理，关键器件寿命预测，智能预警； 电网停电时，作为备用电源使用紧急备电。",
            backgroundImg: 'EnergyStorageSolution-5',
            type: '4',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"灵活应用",
            title: '光储充系统',
            desc:"可与光伏、风能、电网、充电组成光储充系统； 给需要的车辆充电，临近设施做应急电源； 支持并离网无缝切换、减少停电干扰； 适用于大型商场、工业园区、小区住宅等场景。。",
            backgroundImg: 'EnergyStorageSolution-6',
            type: '5',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },
        {
            title: '体验超级充电',
            type: '8',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
            backgroundImg: 'super-charge-8'
        },
    ])

    
    const [listEnData, setListEnData] = useState([
    {
        title: 'Energy Storage Solution',
        desc:"",
        backgroundImg: 'energy-storage-solution-1',
        range:'',
        speed:'',
        top:'',
        type: '1',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Renewable Energy",
        title: 'Energy Storage Solution',
        desc:"The photovoltaic storage and charging inspection solution integrates five major functions: clean renewable energy access, safe and reliable energy storage technology, efficient photovoltaic power generation technology, high-power fast-charging technology for electric vehicles, and intelligent system detection and management technology. This forms a new type of intelligent infrastructure. Through the intelligent interaction of these five functions, a coordinated operational model is formed among the power grid, photovoltaic storage and charging inspection, integrated power stations, and electric vehicles. This model ensures the safe, economical, and efficient operation of clean electricity, supporting the goal of carbon neutrality.",
        backgroundImg: 'EnergyStorageSolution-2',
        type: '4',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Economically Efficient",
        title: 'No Need for Grid Expansion, Saving Investment Costs',
        desc:"The power grid does not require expansion, which saves on investment costs. By shaving peak demand and filling the valleys, electricity costs are reduced. The efficient use of energy lowers the pressure on the urban grid load. The system is capable of peak regulation, frequency modulation, voltage control, suppressing load fluctuations, and reducing losses in the power system. Through green electricity trading and ancillary services, additional revenue can be obtained.",
        backgroundImg: 'EnergyStorageSolution-3',
        type: '6',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Convenient Installation",
        title: 'Standard Modular Design',
        desc:"The standard modular design effectively reduces the complexity of installation, shortens the construction period, and achieves rapid deployment and installation.",
        backgroundImg: 'EnergyStorageSolution-4',
        type: '5',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Safe and Reliable",
        title: 'Comprehensive Real-Time Monitoring',
        desc:"Comprehensive real-time monitoring enables proactive management of energy flow in the power network; intelligent system detection supports custom regulation rules and multi-dimensional functional alerts; equipment health management with life prediction for critical components and intelligent early warning systems; in the event of a power grid outage, the system serves as an emergency backup power source.",
        backgroundImg: 'EnergyStorageSolution-5',
        type: '4',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Flexible Application",
        title: 'Photovoltaic Storage and Charging System',
        desc:"The system can be integrated with photovoltaic, wind energy, the power grid, and charging facilities to form a photovoltaic storage and charging system; it provides charging for vehicles in need and serves as an emergency power source for nearby facilities; supports seamless on-grid and off-grid switching, reducing power outage disturbances; suitable for scenarios such as large shopping malls, industrial parks, and residential communities.",
        backgroundImg: 'EnergyStorageSolution-6',
        type: '5',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },
    {
        title: 'Experience supercharging',
        type: '8',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
        backgroundImg: 'super-charge-8'
    },
    ])

    const [renderData, setRenderData] = useState(language? listData:listEnData)


    useEffect(()=>{
        if(language){
            document.title = "储能方案";
            setRenderData(()=> listData)
        }else{
            document.title = "Energy storage solutions";
            setRenderData(()=> listEnData)
        }
    },[language])

    useEffect(()=>{
        document.querySelector('.page-content').scrollTop = 0
    },[renderData])
    return(
        <Wrapper className="page-content">
            <Header
                setPopupType={setPopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            {renderData.map((e,i)=>{
                if(e.type === '1'){
                    return (
                        <Content key={i}>
                            <Section 
                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                range={e.range}
                                speed={e.speed}
                                top={e.top}

                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}

                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                }else if(e.type === '4'||e.type === '5'||e.type === '6'){
                    return (
                        <Content key={i}>
                            <BaseComponent 
                                subTitle={e.subTitle}
                                type={e.type}

                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                bgData={e.bgData}
                                
                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}

                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                } else if (e.type === '7') {
                    return (<Content key={i}>
                        <Section5 
                            backgroundImg={'EnergyStorageSolution-7'} 
                            setPopupType={setPopupType}
                            setOpen={setOpen}
                        />
                    </Content>
                    )
                } else if (e.type === '8') {
                    return (
                        <Content key={i}>
                            <Section2 
                                bg={e.backgroundImg} 
                                title={e.title} 
                                leftbtn={e.leftbtn} 
                                rightbtn={e.rightbtn}
                                setPopupType={setPopupType}
                                setOpen={setOpen}
                            />
                        </Content>
                    )
                }
            })}

            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />
            
            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
    )
}

export default EnergyStorageSolution;