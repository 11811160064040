import React,{ useState, useEffect } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import { Link, useNavigate } from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';

import './header.css'

const Header = ({setPopupType, setOpen, language, setLanguage}) => {
	const navigate = useNavigate();
	const [openSideNav,setOpenSideNav] = useState(false);
	let [headerStyle, setHeaderStyle] = useState({
	  width: '0',
	  position: '0',
	  opacity: 0
	})
	let [navList, setNavList] = useState([
		{title:'首页',link: '/'},
		{title:'超级充电',link: '/power-bank'},
		{title:'能源路由器',link: '/energy-router'},
		{title:'储能方案',link: '/energy-storage-solution'},
		{title:'专业服务',link: '/service'},
	])
	
	const [navEnList, setNavEnList] = useState([
		{title:'Home',link: '/'},
		{title:'Supercharging',link: '/power-bank'},
		{title:'Energy router',link: '/energy-router'},
		{title:'Energy storage solutions',link: '/energy-storage-solution'},
		{title:'Professional Services',link: '/service'},
	])

	const [renderData, setRenderData] = useState(language? navList:navEnList)


	useEffect(()=>{
		if(language){
			setRenderData(()=> navList)
		}else{
			setRenderData(()=> navEnList)
		}
	},[language])


	// 鼠标移入事件
	const move = (ev) =>{
	  if(ev.target.className === 'nav_item'){
			// console.log('className',ev.target.className)
			// console.log('宽',ev.target.clientWidth)
			// console.log('高',ev.target.clientHeight)
			// console.log('左侧间距',ev.target.offsetLeft)
			// console.log('左侧间距',ev.target.offsetLeft)
			setHeaderStyle({
				width: ev.target.clientWidth+'px',
				position: ev.target.offsetLeft+'px',
				opacity: 1
			})
	  }
	}
  
		// 鼠标移出事件
		const out = (ev) =>{
		//   console.log('ev',ev)
			setHeaderStyle({
			width: headerStyle.width,
			position: ev.target.offsetLeft+'px',
			opacity: 0
			})
		}


    return (
			<>
				<Wrapper>
					<div className='logo' >
						<Link to='/'>
							<img src='/images/logo.png' alt='logo' loading="lazy"/>
						</Link>
					</div>
					<Content>
						<div 
							className='nav_bg'
							style={{
								width: headerStyle.width,
								height: 42,
								left: headerStyle.position,
								opacity: headerStyle.opacity,
							}}
						></div>
						<div className='nav_list'>
							{renderData.map((e,i)=>{
								return <div 
									key={i} 
									className="nav_item"
									onMouseMove={(ev)=> move(ev)}
									onMouseOut={(ev)=> out(ev)}
									onClick={()=> navigate(e.link)}
								>
									{e.title}
								</div>
							})}
						</div>
					</Content>
					<Content1>
						{/* <a href='#' className='none' >Shop</a> */}
						{/* TODO：账户路由更改 */}
						{/* to='/login' */}
						{/* <Link className='none' style={{color:'#fff'}}>账户</Link> */}
						<div style={{color:'#fff',lineHeight: "34px",marginRight:20}}>
							<span 
								style={language? {color:'rgb(22,93,255)', cursor: 'pointer'}:{color:'#fff', cursor: 'pointer'}} 
								onClick={()=> setLanguage(true)}
							>中文</span>
							<span style={{color:'#fff', margin:'0 5px'}}>|</span>
							<span
								style={!language? {color:'rgb(22,93,255)', cursor: 'pointer'}:{color:'#fff', cursor: 'pointer'}} 
								onClick={()=> setLanguage(false)}
							>EN</span>
						</div>
						<div onClick={()=> setOpenSideNav(true)} style={{color:'#fff',lineHeight: '34px', cursor: 'pointer'}}>	
							{language? <>导航栏</>:<>Navigation bar</>}
						</div>
					</Content1>
					{openSideNav && 
						<div className='top'>
							<div className='close' onClick={()=> setOpenSideNav(false)}>
								<CloseIcon/>
							</div>
						</div> 
					}
					<SideNav 
						show={openSideNav}
						setOpenSideNav={setOpenSideNav}
						setPopupType={setPopupType}
						setOpen={setOpen}
						language={language}
						setLanguage={setLanguage}
					/>
        </Wrapper>
			</>
    )
}

export default Header