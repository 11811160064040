import React,{useEffect, useState} from 'react'

import { Wrapper,Content } from "./model3.style";

import Section from "../../components/Section1";
import Section2 from "../../components/Section2";
import Section6 from "../../components/Section6";
import BaseComponent from "../../components/Section4";
import Section5 from "../../components/Section5";
import CompanyDetail from '../../components/Section6'
import Header from "../Header";
import Footer from "../Footer";

import Popup from '../../components/Popup/Popup'

const Service = ({language, setLanguage}) =>{
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    document.title = "一站式超充场站建设服务方案";
    const [listData, setListData] = useState([{
            title: '一站式超充场站建设服务方案',
            desc:"",
            backgroundImg: 'service-1',
            range:'',
            speed:'',
            top:'',
            type: '1',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"专业高效",
            title: '顾全当下，着眼未来',
            desc:"我们拥有专业高效的研发团队和服务团队，能根据使用环境和用户需求，提供风光储超 级充电站的立项评估、选址规划、规模设计、扩容升级等服务，制定切合实际的软硬件专业 解决方案，在满足当下需求的同时，兼顾未来发展变化。",
            backgroundImg: 'service-2',
            type: '4',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"设备关键性",
            title: '焕新服务',
            desc:"设备关键性能始终保持出场标准 7X24 小时响应，无法远程修复的，4 小时启动现场飞行服务 全周期内免费原厂备件换新服务 预防式巡检与保养服务 >99.9%可用性保证易送电服务（运营）。",
            backgroundImg: 'service-3',
            type: '5',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"技术支持",
            title: 'ECS易送电服务 \nEasy Charging Service',
            desc:"建站服务：“易送电”场站的需求调研、规划设计、安装调试 机构审批服务：配合甲方申请充电站相关资质与合规性文件 场站运营服务：场站运营与技术支持",
            backgroundImg: 'service-4',
            type: '4',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"更多服务",
            title: '想你所想，及你所需',
            desc:"为了体现以人为本，用户至上的企业理念；我们还会提供内容包括但不限于：现场安装 与施工指导、场站升级迁移、建站资质申请支持、设备物流运输等服务。",
            backgroundImg: 'service-5',
            type: '6',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },{
            subTitle:"品牌特性",
            title: '设备租赁服务',
            desc:"我们提供产品租赁合作模式，可以按照年、季度等不同时间维度为用户提供产品支持， 展现品牌特性。",
            backgroundImg: 'service-6', 
            type: '5',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
        },
    ])

    
    const [listEnData, setListEnData] = useState([{
        title: 'One stop overcharging station construction service plan',
        desc:"",
        backgroundImg: 'service-1',
        range:'',
        speed:'',
        top:'',
        type: '1',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Professional and Efficient",
        title: 'Considering the Present, Focused on the Future',
        desc:"We have a professional and efficient research and development team and service team capable of providing comprehensive services for wind-solar-storage supercharging stations, including project evaluation, site selection planning, capacity design, and expansion upgrades, based on the usage environment and user requirements. We develop practical hardware and software solutions that meet current needs while also taking into account future developments and changes.",
        backgroundImg: 'service-2',
        type: '4',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Critical Equipment",
        title: 'Revitalization Service',
        desc:"The critical performance of the equipment consistently maintains factory standards with a 7x24 hour response. If remote repair is not possible, a 4-hour onsite fly-in service will be initiated. Throughout the entire cycle, free original factory spare parts replacement service is provided. Preventative inspection and maintenance services are included, with a >99.9% availability guarantee for the Easy Electricity Delivery Service (operations).",
        backgroundImg: 'service-3',
        type: '5',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Technical Support",
        title: 'ECS Easy Electricity Delivery Service \nEasy Charging Service',
        desc:`Station Construction Services: Needs assessment, planning, design, installation, and commissioning for "Easy Electricity Delivery" stations.Regulatory Approval Services: Assisting the client in applying for charging station qualifications and compliance documents.Station Operation Services: Operation and technical support for stations.`,
        backgroundImg: 'service-4',
        type: '4',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Additional Services",
        title: 'Anticipating Your Thoughts, Catering to Your Needs',
        desc:"To embody our people-oriented and customer-first corporate philosophy, we offer services that include, but are not limited to: on-site installation and construction guidance, station upgrade and relocation, support for station construction qualification applications, equipment logistics and transportation, and more.",
        backgroundImg: 'service-5',
        type: '6',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Brand Characteristics",
        title: 'Equipment Leasing Services',
        desc:"We offer a product leasing partnership model, providing product support to users based on different time dimensions such as yearly or quarterly, showcasing our brand's distinctive features.",
        backgroundImg: 'service-6', 
        type: '5',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },
    ])

    const [renderData, setRenderData] = useState(language? listData:listEnData)


    useEffect(()=>{
        if(language){
            document.title = "一站式超充场站建设服务方案";
            setRenderData(()=> listData)
        }else{
            document.title = "One stop overcharging station construction service plan";
            setRenderData(()=> listEnData)
        }
    },[language])

    useEffect(()=>{
        document.querySelector('.page-content').scrollTop = 0
    },[renderData])
    return(
        <Wrapper className="page-content">
            <Header
                setPopupType={setPopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            {renderData.map((e,i)=>{
                if(e.type === '1'){
                    return (
                        <Content key={i}>
                            <Section 
                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                range={e.range}
                                speed={e.speed}
                                top={e.top}

                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}

                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                }else if(e.type === '4'||e.type === '5'||e.type === '6'){
                    return (
                        <Content key={i}>
                            <BaseComponent 
                                subTitle={e.subTitle}
                                type={e.type}

                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                bgData={e.bgData}
                                
                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}

                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                } else if (e.type === '8') {
                    return (
                        <Content key={i}>
                            <Section2 
                                bg={'home-p-5'} 
                                title={e.title} 
                                leftbtn={e.leftbtn} 
                                rightbtn={e.rightbtn}
                                setPopupType={setPopupType}
                                setOpen={setOpen}
                            />
                        </Content>
                    )
                }
            })}
            <Section6></Section6>
            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />

            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
    )
}

export default Service;