import styled from "styled-components";

export const Wrapper = styled.div`
    // min-height: 150vh;
    width: 100vw;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    // align-items: center;
    background-color: #fff;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    max-width: 1050px;
    width: 80%;
    height: 80%;

    
    .row:nth-child(2n-1){
        // height: 40vh;
        display: flex;
        align-items: center;
        .text{
            padding-left: 70px;
            padding-right: 0;
        }
    }

    .row:nth-child(2n){
        // height: 40vh;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        .text{
            padding-left: 0;
            padding-right: 70px;
        }
    }

    img{
        width: 100%;
        // width: 450px;
        min-width: 350px;
        height: 100%;
    }

    .col:nth-child(2n-1){
        width: 450px;
        height: 340px;
    }
    .col{
        width: 100%;
        height: 340px;
        h3{
            color: #000;
            font-weight: 400;
            font-size: 1.4rem;
            letter-spacing: 0.6px;
            padding: 20px 0;
            word-spacing: 2px;
        }
        p{
            color: #000;
            font-weight: 300;
            font-size: 0.95rem;
            letter-spacing: 0.4px;
            word-spacing: 1.2px;
            line-height: 25px;
        }
    }

    .text{
        display:flex;
        flex-direction: column;
        justify-content: center;
        h3, p{
            // padding-left: 120px;
            padding-right: 20px;
        }
    }
    
    @media screen and (max-width: 750px){
        .col{
            h3 ,p{
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    @media screen and (max-width: 400px){
        width: 96%;
        height: 96%;

        .col{
            h3{
                font-size: 1.2rem;
            }
            p{
                font-size: 0.8rem;
                line-height: 18px;
            }
        }
    }

`;

export const ContentPhone = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    max-width: 1050px;
    width: 96%;
    height: 80%;

    img{
        width: 100%;
        // width: 450px;
        min-width: 350px;
        height: 100%;
        margin-top: 30px;
    }

    .text{
        display:flex;
        flex-direction: column;
        justify-content: center;
        h3, p{
            // padding-left: 120px;
            margin-top: 10px;
            padding-right: 20px;
        }
    }
    
`