import React,{useEffect, useState} from 'react'
import { Wrapper, Content, AboutImg, NoImageWrapper, ExprienceContent, InfinityContent, BottomJoinContent, InnerBg } from "./model3.style";
import Section from "../../components/Section1";
import Header from "../Header";
import Footer from "../Footer";
import Popup from '../../components/Popup/Popup'

const NewAbout = ({language, setLanguage}) =>{
    document.title = "关于大龙新能源";
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    const [listData, setListData] = useState([{
        type: '1',
        title:"事业",
        desc:"智慧新能源",
        text: `大龙新能源是专业的电动汽车基础设施及智慧交通能源解决方案提供商。致力于把新能源世界带给每一位用户，构建可持续能源的未来。\n立于2021年，专注安全可信赖的大功率充放电设备的研发、生产制造与服务，与全球电动汽车制造商、政府、运营商等用户保持长期深入合作。`
    },{
        type: '2',
        title:"专业",
        desc:"技术驱动",
        listText:[
            `· 连续四次服务中国神舟飞船发射工程，获“神舟载人航天突出贡献单位”称号`,
            `· 世界首创高电压串谐试验装置`,
            `· 中国首创AC-OWTS（电缆交流振荡波局放检测系统）`,
            `· 国家行业标准《10kV 预装式充电站技术规范》制定参与者`,
            `· 2021年第十届中国创新企业优秀奖`,
            `· 江苏省首台套、江苏省重点推广应用的新技术新产品、江苏省创新产品`,
            `· 变充一体多项发明专利`
        ]
    },{
        type: '2',
        title:"初心",
        desc:"安全源自设计",
        listText:[
            `· 安全快速、高效节能、紧凑简约`,
            `· 推动国家科技创新、低碳经济建设，走向“绿色、智能”的未来`
        ]
    },{
        type: '3',
        title:"进化",
        desc:"十年潜心，面向未来",
        listMap:[{
            title: `2013年`,
            mapItem: [`投建高新区交通车充电站(360kW)`, `建成交流充电桩直流桩广告屏桩生产车间`]
        },{
            title: `2014年`,
            mapItem: [`国内最早推出便携式充电机(15/20kW)`]
        },{
            title: `2015年`,
            mapItem: [`生产挂壁和立柱直流桩(20/40/60kW)`]
        },{
            title: `2016年`,
            mapItem: [`研发第一代变充一体充电站(10kV/800kW)`, `为中石化研发分体式充电桩(480kW)`]
        },{
            title: `2017年`,
            mapItem: [`中标北京中石化`, `七个充电站项目`]
        },{
            title: `2018年`,
            mapItem: [`承建金龙东沟长途车站的充电站(1600kW)`, `中标南京公交珍珠泉等六座充电站(1600kW)`]
        },{
            title: `2019年`,
            mapItem: [`生产10kV移动站`]
        },{
            title: `2020年`,
            mapItem: [`第三代充电站完成`, `承建岳阳公交(2000kW)`]
        },{
            title: `2022年`,
            mapItem: [`主编10kV预装式充电站技术规范`]
        }]
    }])

    

    return(
        <Wrapper>
            <Header
                setPopupType={setPopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            
            <Content >
                <AboutImg bg={'home-p-2'}>
                    <div className="about-bg-title">
                        我们帮助连接可持续能源的世界
                    </div>
                </AboutImg>
                <div className="about-detail">
                    {listData.map((e,i)=>{
                        return (
                            <div key={i} className="detail-item">
                                <div className="title">{e.title}</div>
                                <div className="desc">{e.desc}</div>
                                {e.type === "1"? 
                                    <div className="list">{e.text}</div>
                                    :
                                e.type === "2"?
                                    <div className="listText">
                                        {e.listText.map((E,I)=>{
                                            return (<div key={I}>{E}</div>)
                                        })}
                                    </div>
                                    :
                                e.type === "3"?
                                    <div className="listMap">
                                        {e.listMap.map((E,I)=>{
                                            return (
                                                <div key={I} className="listMap-item">
                                                    <div>{E.title}</div>
                                                    {E.mapItem.map((item,index)=> <div key={index}>{item}</div>)}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    ''
                                }  
                            </div>
                        )
                    })}
                </div>
            </Content>

            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />

            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
    )
}

export default NewAbout;
