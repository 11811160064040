import React,{useEffect} from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import { Wrapper, Content } from "./section.style";

const CompanyDetail = ({language, setLanguage}) =>{
    
    useEffect(() => {
        AOS.init({duration: 1400});
    }, []);

    return(
        <Wrapper>
            {language? 
							<div style={{textAlign:"center"}}>
								<div className="title" style={{marginBottom:20}}>如果您有任何疑问或需要提供帮助</div>
								<div className="title" style={{marginBottom:30}}>请与我们及时联系，我们将尽快给您回复，感谢您一直以来的支持！</div>
								{/* <div className="subTitle">服务热线</div>
								<div className="desc">contact@dalong.com</div> */}
								<div className="subTitle">服务邮箱</div>
								<div className="desc">contact@dalong.com</div>
								{/* <div className="subTitle">微信号</div>
								<div className="desc" style={{margin:0}}>
										<img src={'/images/QR_code.jpg'} style={{width: '25%'}}/>
								</div> */}
							</div>
							:
							<div style={{textAlign:"center"}}>
								<div className="title" style={{marginBottom:20}}>If you have any questions or require assistance</div>
								<div className="title" style={{marginBottom:30}}>please do not hesitate to contact us. We will respond to you as soon as possible. Thank you for your continued support!</div>
								{/* <div className="subTitle">服务热线</div>
								<div className="desc">contact@dalong.com</div> */}
								<div className="subTitle">Service Email:</div>
								<div className="desc">contact@dalong.com</div>
								{/* <div className="subTitle">微信号</div>
								<div className="desc" style={{margin:0}}>
										<img src={'/images/QR_code.jpg'} style={{width: '25%'}}/>
								</div> */}
							</div>
            }
        </Wrapper>
    )
}

export default CompanyDetail;