import styled from "styled-components";

export const Wrapper = styled.div`
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`;

export const ImageWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:  ${props => `url('/images/${props.bg}.png'), url('/images/${props.bg}-small.webp')`};/url is by default in public section
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`

export const JoinContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .main {
        margin-top: 10vh;
        font-size: 26px;
        font-weight: 500;
        color: #333333;
    }
    .info {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin: 20px;
        width: 80vw;
    }
    .btn-join{
        border: 2px solid #333;
        width: 190px;
        height: 36px;
        background-color: #fff;
        cursor: pointer;
        color: #000;
        border-radius: 4px;
    }
    .btn-join:hover {
        border: 2px solid #333;
        width: 190px;
        height: 36px;
        background-color: #000;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
    }
`

export const NoImageWrapper = styled.div`
    width: 100vw;
    height: ${props => props.height};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background: #000;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};

    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`

export const ExprienceContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5vh;
    .main {
        margin: 5vh auto 2vh auto;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
    }
    .info {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        margin: 5vh 0;
        max-width: 70vw;
    }
    .info-wrapper{
        width: 80vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: spsace-between;
    }
    .info-item:not(:last-child){
        margin-right: 20px;
    }
    .info-item {
        width: 453px;
        margin-top: 5vh;
    
        .line{
            width: 100%;
            height: 3px;
            background-color: gray;
        }

        .main {
            font-size: 14px;
            color: gray;
        }

        .sub {
            font-size: 12px;
            color: gray;
        }
    }
    .info-item:hover .line{
        background: #fff;
        cursor: pointer;
    }
    .info-item:hover .main, .info-item:hover .sub{
        color: #fff;
        cursor: pointer;
    }
    
    @media screen and (max-width: 600px){
        margin-left: auto;
        overflow-x: scroll;
        .info-wrapper {

        }
        .info {
            max-width: 60vw;
        }
        .info {
            max-width: 60vw;
        }    
    }
`

export const InnerBg = styled.div`
    width: 80vw;
    height: 40vh;
    border-radius: 10px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:  ${props => `url('/images/${props.bg}.png'), url('/images/${props.bg}-small.webp')`};   
    margin: 5vh;
`

export const InfinityContent = styled.div`
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${props => props.color};
    margin-bottom: ${props => props.marginBottom};
    .main{
        line-height: 30px;
        font-size: 26px;
        margin-right: 200px;
        color: ${props => props.color};
    }
    .info{
        font-size: 12px;
        line-height: 30px;
        width: 600px;
        color: ${props => props.color};
    }
    @media screen and (max-width: 600px){
        flex-direction: column;
        .main {
            margin-right: 0;
            margin-bottom: 5vh;
        }
        .info {
            width: 70vw;
        }
    }
`

export const BottomJoinContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
        margin-top: 50vh;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 50px;
    }

    .btn-join-last:hover{
        border: 3px solid #fff;
        width: 190px;
        height: 36px;
        background-color: #000;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
    }
    .btn-join-last{
        border: 3px solid #fff;
        width: 190px;
        height: 36px;
        background-color: #fff;
        color: #000;
        cursor: pointer;
        border-radius: 4px;
    }
`

