import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root{
        --dalongColor : #393c41;
    }
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family:"Rubik" , sans-serif;
        color: #393c41;
    }
    body{
        width: 100%;
        overflow-x: hidden;
        
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        ::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
    }
`;

export default GlobalStyle