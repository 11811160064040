import React from 'react'
import { Wrapper,Content, BG} from './popup.style'

import { Link } from 'react-router-dom'

import CompanyDetail from '../../components/Section6'
import Story from '../../components/Section7'

function Popup({ show, icon, PopupType, setOpen, language, setLanguage }) {
    return (
        <Wrapper show={show}>
            <Content show={show}>
                <img 
                    alt=''
                    className="close"
                    src={`/images/${ PopupType === '联络我们'? 'close-black.png':'close-white.png' }`}
                    onClick={()=> setOpen(false)}
                    loading="lazy"
                />
                {PopupType === '联络我们'? 
                    <CompanyDetail 
                        language={language}
                        setLanguage={setLanguage}
                    />
                    :
                    <Story />
                }
                
            </Content>
        </Wrapper>
    )
}
export default Popup