import React,{useEffect, useState} from 'react'
import { Wrapper,Content } from './home.style'

import Section from '../components/Section1'
import Interior from '../components/Section2'
import Header from './Header';
import Footer from './Footer';

import Popup from '../components/Popup/Popup'

function Home({language, setLanguage}) {
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    const [listData, setListData] = useState([{
        type: '1',
        title:"超级充电",
        desc:"标准快充/重卡超充/移动式超充",

        range:'10',
        speed:'<6',
        top:'30000+',

        leftbtn:"联络我们",
        rightbtn:"用户故事",
        link:"/power-bank",
        backgroundImg:'home-p-1',
    },{
        type: '1',
        title:"能源路由器",
        desc:"标准快充/重卡超充/移动式超充",
        leftbtn:"联络我们",
        rightbtn:"用户故事",
        link:"/energy-router",
        backgroundImg:'home-p-2',
    },{
        type: '1',
        title:"储能方案",
        desc:"标准快充/重卡超充/移动式超充",
        leftbtn:"联络我们",
        rightbtn:"用户故事",
        link:"/energy-storage-solution",
        backgroundImg:'home-p-3',
    },{
        type: '1',
        title:"专业服务解决方案",
        desc:"标准快充/重卡超充/移动式超充",
        leftbtn:"联络我们",
        rightbtn:"用户故事",
        link:"/service",
        backgroundImg:'home-p-4',
    },{
        type: '2',
        title:"体验超级充电",
        link:"",
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
        backgroundImg:'home-p-5',
    }])
    const [listEnData, setListEnData] = useState([{
        type: '1',
        title:"Supercharging",
        desc:"Standard fast charging/Heavy-duty supercharging/Portable supercharging",

        range:'10',
        speed:'<6',
        top:'30000+',

        leftbtn:"Contact us",
        rightbtn:"User stories",
        link:"/power-bank",
        backgroundImg:'home-p-1',
    },{
        type: '1',
        title:"Energy router",
        desc:"Standard fast charging/Heavy-duty supercharging/Portable supercharging",
        leftbtn:"Contact us",
        rightbtn:"User stories",
        link:"/energy-router",
        backgroundImg:'home-p-2',
    },{
        type: '1',
        title:"Energy storage solutions",
        desc:"Standard fast charging/Heavy-duty supercharging/Portable supercharging",
        leftbtn:"Contact us",
        rightbtn:"User stories",
        link:"/energy-storage-solution",
        backgroundImg:'home-p-3',
    },{
        type: '1',
        title:"Professional service solutions",
        desc:"Standard fast charging/Heavy-duty supercharging/Portable supercharging",
        leftbtn:"Contact us",
        rightbtn:"User stories",
        link:"/service",
        backgroundImg:'home-p-4',
    },{
        type: '2',
        title:"Experience supercharging",
        link:"",
        leftbtn:"Contact us",
        // rightbtn:"User stories",
        backgroundImg:'home-p-5',
    }])

    const [renderData, setRenderData] = useState(language? listData:listEnData)
    

    useEffect(()=>{
        console.log(renderData)
        if(language){
            document.title = "首页";
            setRenderData(()=> listData)
        }else{
            document.title = "Home";
            setRenderData(()=> listEnData)
        }
    },[language])

		
    useEffect(()=>{
        document.querySelector('.home-content').scrollTop = 0
    },[renderData])

    return (
        <>
        <Header
            setPopupType={setPopupType}
            setOpen={setOpen}
            language={language}
            setLanguage={setLanguage}
        />
        <Wrapper className='home-content'>
					{renderData.map((e,i)=>{
						if(e.type === '1'){
							return (   
								<Content key={i}>
									<div data-src={e.backgroundImg}>
										<Section 
											title={e.title}
											desc={e.desc}
											link={e.link} 
											leftbtn={e.leftbtn} 
											rightbtn={e.rightbtn}
											backgroundImg={e.backgroundImg}
											range={e.range}
											speed={e.speed}
											top={e.top}
											setPopupType={setPopupType}
											setOpen={setOpen}

                                            language={language}
                                            setLanguage={setLanguage}
										/>
									</div>
								</Content>
							)
						}else if(e.type === '2'){
							return (
								<Content key={i}>
									<Interior
										title={e.title}
										link={e.link} 
										leftbtn={e.leftbtn} 
										rightbtn={e.rightbtn}
										bg={e.backgroundImg}
										setPopupType={setPopupType}
										setOpen={setOpen}
									/>
								</Content>
							)
						}
					})}

            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
        
        </>
    )
}

export default Home