import React,{ useEffect, useState } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";
import '../../baseStyle.css'

import LazyLoad from 'react-lazyload';
import { Wrapper,Content } from "./section5.style";


// type: "4"-左   "5"-右   "6"-下
const Section5 = ({backgroundImg, title, desc, type, leftbtn, rightbtn, setPopupType, setOpen, language, setLanguage}) =>{
    const [listData, setListData] = useState([
        {
            title: '环境指标',
            items: [
                {name:'工作温度', value:'-25-60℃'},
                {name:'相对湿度', value:'5%-95%'},
                {name:'海拔高度', value:'≤2000（≥-2000署中朋）'},
                {name:'防护等级', value:'P85（解决模块“三怕”和“三防”）'},
                {name:'储存温度', value:'-40-70℃'},
                {name:'散热方式', value:'就冷酸热（双交频工业制冷机海）'},
                {name:'噪声指标', value:'≤55dB'},
            ]
        },{
            title: '输入参数',
            items: [
                {name:'输入电压', value:'三相10KV'},
                {name:'电源频率', value:'45-65Hz（频定50-60Hz）'},
                {name:'功率因数', value:'≥98%（负载≥50%）'},
                {name:'谐波', value:'≤5%（负载≥50%）'}
            ]
        },{
            title: '输出参数',
            items: [
                {name:'输出电压', value:'200-1000V'},
                {name:'输出纹波', value:'≤1%'},
                {name:'稳压精度', value:'≤±0.5%'},
                {name:'横流精度', value:'≤±1%（负载20%-100%）'},
                {name:'恒功率输出', value:'分段恒功率'},
                {name:'FPDD', value:'全功率动志分配（可选）'},
                {name:'单枪输出功率', value:'60/120/180kW（ 非冷却）', value2:'360/600kW（冷却）'},
                {name:'协同充电', value:'双枪/多枪协同充电'},
            ]
        },{
            title: 'AI和支付',
            items: [
                {name:'视频识别', value:'车牌识别/人脸识别（可选）'},
                {name:'语音提示', value:'语音提示/语音识别（可选）'},
                {name:'支付方式', value:'NFC/二維码/VIN-/影卡'},
            ]
        },{
            title: '输入参数',
            items: [
                {name:'待机功耗', value:'零功耗待机'},
                {name:'线缆损耗', value:'电缆线电阻相同时10kv是380线损的1/625，实际线损'},
                {name:'充电计量损耗', value:'零损耗零漂移'},
                {name:'整机效率', value:'>94%（额定负载）'},
            ]
        },{
            title: '基本指标',
            items: [
                {name:'充电桩尺寸', value:'3000*1500*2600mm'},
                {name:'充电桩重量', value:'4500kg'},
            ]
        },
    ])

    const [state, setState] = useState(false)

    const [listEnData, setListEnData] = useState([
        {
            title: 'Environmental Indicators',
            items: [
                {name:'工作温度', value:'-25-60℃'},
                {name:'Relative Humidity', value:'5%-95%'},
                {name:'Altitude', value:'≤2000（≥-2000署中朋）'},
                {name:'Protection Level', value:'P85（解决模块“三怕”和“三防”）'},
                {name:'Storage Temperature', value:'-40-70℃'},
                {name:'Cooling Method', value:'就冷酸热（双交频工业制冷机海）'},
                {name:'噪声指标', value:'≤55dB'},
            ]
        },{
            title: 'Input parameters',
            items: [
                {name:'Input voltage', value:'三相10KV'},
                {name:'Power supply frequency', value:'45-65Hz（频定50-60Hz）'},
                {name:'Power factor', value:'≥98%（负载≥50%）'},
                {name:'Harmonics', value:'≤5%（负载≥50%）'}
            ]
        },{
            title: 'Output parameters',
            items: [
                {name:'Output voltage', value:'200-1000V'},
                {name:'Output ripple', value:'≤1%'},
                {name:'Voltage regulation accuracy', value:'≤±0.5%'},
                {name:'Constant current accuracy', value:'≤±1%（负载20%-100%）'},
                {name:'Constant power output', value:'Segmented Constant power'},
                {name:'FPDD', value:'Full power dynamic allocation（可选）'},
                {name:'单枪输出功率', value:'60/120/180kW（ 非冷却）', value2:'360/600kW（冷却）'},
                {name:'协同充电', value:'双枪/多枪协同充电'},
            ]
        },{
            title: 'AI and Payment',
            items: [
                {name:'Video Recognition', value:'License Plate Recognition/Facial Recognition'},
                {name:'Voice Prompt', value:'Voice Prompt/Voice Recognition'},
                {name:'Payment Methods', value:'NFC/QR Code/VIN-/Shadow Card'},
            ]
        },{
            title: 'Input parameters',
            items: [
                {name:'Standby power consumption', value:'Zero power consumption standby'},
                {name:'Cable loss', value:'When the resistance of the cable is the same, the 10kV line loss is 1/625 of the 380V line loss; actual line loss'},
                {name:'Charging metering loss', value:'Zero loss and zero drift'},
                {name:'Overall machine efficiency', value:'>94%（Rated load）'},
            ]
        },{
            title: 'Basic Indicators',
            items: [
                {name:'Charging pile dimensions', value:'3000*1500*2600mm'},
                {name:'Charging pile weight', value:'4500kg'},
            ]
        }])
        
    const [renderData, setRenderData] = useState(language? listData:listEnData)


    useEffect(()=>{
        if(language){
            setRenderData(()=> listData)
        }else{
            setRenderData(()=> listEnData)
        }
    },[language])


    const resizeUpdate = (ev) =>{
        console.log(ev.target.innerWidth)
        if(ev.target.innerWidth >= 600){
            setState(true)
        }else{
            setState(false)
        }
    }

    useEffect(() => {
        AOS.init({duration: 1400});

        if(window.innerWidth >= 600){
            setState(true)
        }else{
            setState(false)
        }
        window.addEventListener('resize', resizeUpdate)
        return ()=>{
            window.removeEventListener('resize', resizeUpdate)
        }
    }, []);

    const btn_Fn = (type) =>{
        if(type === '联络我们'||type === '用户故事'){
            setPopupType(type)
            setOpen(true)
        }
    }
    
    return(
        <>
            {state? 
                <Wrapper bg={backgroundImg}>
                    <Content >
                        <div>
                            <div className="title">
                                {language? '技术指标':'Technical Specifications'}
                                <div className="model-item">GX-M 600/380V</div>
                            </div>
                            <div className="detail-box">
                                {renderData.map((e,i)=>{
                                    return (
                                        <div className="detail-list" key={i}>
                                            <div className="list-title">
                                                {e.title}
                                            </div>
                                            {e.items.map((E,I)=>{
                                                return(
                                                    <div className="list-item" key={I}>
                                                        <div className="item-line"></div>
                                                        <div className="item-name">{E.name}</div>
                                                        <div className="item-value">{E.value}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <button className="w190 btn-white" onClick={()=> btn_Fn('联络我们')}>
                                {language? '联络我们':'Technical Specifications'}
                            </button>
                        </div>
                    </Content>
                </Wrapper>
                :
                <Wrapper bg={backgroundImg}>
                    <Content style={{height: '95vh',paddingTop: 60}}>
                        <div style={{height:'-webkit-fill-available'}}>
                            <div className="title" style={{padding:'0 10px'}}>
                                {language? '技术指标':'Technical Specifications'}
                                <div className="model-item">GX-M 600/380V</div>
                            </div>
                            <div 
                                className="detail-box" 
                                style={{display: 'block', padding:'0 10px', marginTop: 10, height: '85%', overflowY:'auto'}}
                            >
                                {renderData.map((e,i)=>{
                                    return (
                                        <div 
                                            className="detail-list" 
                                            key={i} 
                                            style={{
                                                width: '100%',
                                                minWidth: '100%',
                                                display:'flex', 
                                                flexWrap:'wrap'
                                            }}
                                        >
                                            <div className="list-title" style={{width: '100%'}}>
                                                {e.title}
                                            </div>
                                            {e.items.map((E,I)=>{
                                                return(
                                                    <div className="list-item" key={I} style={{width: '50%'}}>
                                                        <div className="item-line"></div>
                                                        <div className="item-name">{E.name}</div>
                                                        <div className="item-value">{E.value}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <button className="w190 btn-white" style={{marginBottom: 20}} onClick={()=> btn_Fn('联络我们')}>
                                {language? '联络我们':'Technical Specifications'}
                            </button>
                        </div>
                    </Content>
                </Wrapper>
            }
        </>
    )
}

export default Section5;