import React,{useEffect, useState} from 'react'

import { Wrapper,Content } from "./models.style";

import Section from "../../components/Section1";
import Interior from "../../components/Section2";
import BaseComponent from "../../components/Section4";
import Section5 from "../../components/Section5";
import CompanyDetail from '../../components/Section6'
import Header from "../Header";
import Footer from "../Footer";

import Popup from '../../components/Popup/Popup'

const PowerBank = ({language, setLanguage}) =>{
    document.title = "超级充电";
    const [PopupType, setPopupType] = useState('')
    const [open,setOpen] = useState(false);
    const [listData, setListData] = useState([{
        title: '超级充电',
        desc:"",
        backgroundImg: 'SuperCharge-1',
        range:'10',
        speed:'<5',
        top:'30000+',
        type: '1',
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
    },{
        subTitle:"超大功率",
        title: '比预想的更快',
        desc:"无需更多投资，即刻引领分钟级超充时代，大龙 10kV 预装式智慧超充站 HV-UFC 超 高电压平台，单枪最大电流达 600A，可在 5 分钟内完成 50%-80%的续航补充，满足乘用、商用、工程、重卡等电车极速补能场景，同时也兼容当下普通快充需求，性 能卓越，缩短更多补能时间，缓解里程焦虑。",
        backgroundImg: 'SuperCharge-2',
        type: '4',
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
    },{
        subTitle:"高效低损",
        title: '更优化的低碳模式',
        desc:"HV-UFC 超高电压平台依据《GB50052-2009 供配电系统设计规范》研发，10KV 接 入方式大幅减少线路损耗，电能利用效率高达 93%（备注），电表传感器零损耗，全模块 动态休眠，全功率动态分配，让设备使用效率更高，减能减排，节约更多成本。",
        backgroundImg: 'SuperCharge-3',
        type: '6',
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
    },{
        subTitle:"快速交付",
        title: '标准模块化式集装箱设计',
        desc:"标准模块化式集装箱设计，缩短建设周期。 全套设备占地面积不大于 6 平方米，相对于传统的“变电站+充电桩”模式更简便快捷 ，上出线结构设计取代了破土埋设电缆的施工过程，缩短建设周期，节约时间成本。",
        backgroundImg: 'SuperCharge-4',
        type: '6',
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
    },{
        subTitle:"拓展升级",
        title: '简单操作让更新及时',
        desc:"设备接口、布线等各模块标准化，定制需求也可同样实现，保证软件持续更新，在拓展 和升级时能精准替换，简单高效完成，降低设备维护成本。",
        backgroundImg: 'super-charge-5',
        type: '5',
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
    },{
        subTitle:"安全稳定",
        title: '随时随地全面把握',
        desc:"细节设计以安全为首，随时随地全面把握 10kV 全绝缘，BMS（电池管理系统）采用 48V 电压供电，规避了交流电接入终端或充 电桩的安全风险，多重保护功能，全面保障供电和操作安全。 IP65/45°C 恒温恒湿模块、双冗余散热设计，快速散热，让设备运行更加稳定可靠。 全方位故障在线监测系统，对关键器件寿命智能预警，帮助工程师精准判断，保证系统 安全、稳定、高效运行。",
        backgroundImg: 'SuperCharge-6',
        bgData: [{
            title:'10kV 全绝缘',
            text:'10kV 全绝缘，BMS（电池管理系统）采用 48V 电压供电，规避了交流电接入终端或充 电桩的安全风险，多重保护功能，全面保障供电和操作安全。'
        },{
            title:'IP65/45°C',
            text:'恒温恒湿模块、双冗余散热设计，快速散热，让设备运行更加稳定可靠。'
        },{
            title:'全方位故障在线监测系统',
            text:'全方位故障在线监测系统，对关键器件寿命智能预警，帮助工程师精准判断，保证系统 安全、稳定、高效运行。'
        }],
        type: '6',
        leftbtn:"联络我们",
        // rightbtn:"用户故事",
    },
    {
        backgroundImg: 'super-charge-7',
        type: '7',
    },
    {
            title: '体验超级充电',
            type: '8',
            leftbtn:"联络我们",
            // rightbtn:"用户故事",
            backgroundImg: 'super-charge-8',
    }])

    const [listEnData, setListEnData] = useState([{
        title: 'Supercharging',
        desc:"",
        backgroundImg: 'SuperCharge-1',
        range:'10',
        speed:'<5',
        top:'30000+',
        type: '1',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Ultra-High Power",
        title: 'Faster than anticipated',
        desc:"With no need for additional investment, immediately lead the era of minute-level ultra-fast charging. Guoxin's 10kV pre-installed smart ultra-fast charging station HV-UFC high-voltage platform delivers a maximum current of up to 600A per gun, capable of replenishing 50% to 80% of the driving range within 5 minutes. It meets the rapid energy replenishment needs for passenger, commercial, construction, and heavy-duty electric vehicles, while also being compatible with current standard fast charging requirements. With exceptional performance, it significantly reduces charging time, alleviating range anxiety.",
        backgroundImg: 'SuperCharge-2',
        type: '4',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"High Efficiency, Low Loss",
        title: 'An optimized low-carbon model',
        desc:`The HV-UFC high-voltage platform is developed in accordance with the "GB50052-2009 Power Supply and Distribution System Design Specification." The 10kV connection method significantly reduces line losses, with an electrical energy efficiency of up to 93% (note). Electricity meter sensors incur zero energy loss. With full-module dynamic sleep mode and full-power dynamic allocation, the equipment operates with higher efficiency, reducing energy consumption and emissions, and saving more costs.`,
        backgroundImg: 'SuperCharge-3',
        type: '6',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Rapid Delivery",
        title: 'Standard Modular Container Design',
        desc:`The standard modular container design shortens the construction period. The full set of equipment occupies an area of no more than 6 square meters, which is more convenient and quicker compared to the traditional "substation + charging pile" model. The overhead line structure design replaces the construction process of trenching and burying cables, which shortens the construction period and saves time costs.`,
        backgroundImg: 'SuperCharge-4',
        type: '6',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Expansion and Upgrade",
        title: 'Simplified Operations for Timely Updates',
        desc:"The standardization of equipment interfaces and wiring simplifies the process of customization, ensuring continuous software updates. During expansion and upgrades, precise replacements can be made efficiently and effortlessly, reducing the maintenance costs of the equipment.",
        backgroundImg: 'super-charge-5',
        type: '5',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },{
        subTitle:"Safe and Stable",
        title: 'Comprehensive Control at All Times',
        desc:"Safety is the priority in our detailed design, ensuring comprehensive control at all times. The system features 10kV full insulation, and the BMS (Battery Management System) utilizes a 48V power supply to avoid the safety risks associated with AC power access to terminals or charging piles. Multiple protection functions are in place to fully ensure the safety of power supply and operations. The IP65/45°C constant temperature and humidity module, along with a dual redundancy cooling design, facilitate rapid heat dissipation, making the operation of the equipment more stable and reliable. A comprehensive fault online monitoring system is implemented for intelligent early warning of the lifespan of critical components, aiding engineers in making precise judgments to ensure the safety, stability, and efficient operation of the system.",
        backgroundImg: 'SuperCharge-6',
        bgData: [{
            title:'10kV Fully Insulated',
            text:'The system is 10kV fully insulated, and the BMS (Battery Management System) is powered by a 48V voltage supply, avoiding the safety risks associated with AC power access to terminals or charging stations. With multiple protective features, it comprehensively ensures the safety of power supply and operations.'
        // },{
        //     title:'IP65/45°C',
        //     text:'恒温恒湿模块、双冗余散热设计，快速散热，让设备运行更加稳定可靠。'
        // },{
        //     title:'全方位故障在线监测系统',
        //     text:'全方位故障在线监测系统，对关键器件寿命智能预警，帮助工程师精准判断，保证系统 安全、稳定、高效运行。'
        }],
        type: '6',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
    },
    {
        backgroundImg: 'super-charge-7',
        type: '7',
    },
    {
        title: 'Experience supercharging',
        type: '8',
        leftbtn:"Contact us",
        // rightbtn:"用户故事",
        backgroundImg: 'super-charge-8',
    }])

    const [renderData, setRenderData] = useState(language? listData:listEnData)


    useEffect(()=>{
        if(language){
            document.title = "超级充电";
            setRenderData(()=> listData)
        }else{
            document.title = "Supercharging";
            setRenderData(()=> listEnData)
        }
    },[language])
    

    useEffect(()=>{
        document.querySelector('.page-content').scrollTop = 0
    },[renderData])
    return(
        <Wrapper className="page-content">
            <Header
                setPopupType={setPopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
            {renderData.map((e,i)=>{
                if(e.type === '1'){
                    return (
                        <Content key={i}>
                            <Section 
                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                range={e.range}
                                speed={e.speed}
                                top={e.top}

                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}
                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                }else if(e.type === '4'||e.type === '5'||e.type === '6'){
                    return (
                        <Content key={i}>
                            <BaseComponent 
                                subTitle={e.subTitle}
                                type={e.type}

                                title={e.title}
                                desc={e.desc}
                                backgroundImg={e.backgroundImg}
                                
                                bgData={e.bgData}
                                
                                leftbtn={e.leftbtn}
                                rightbtn={e.rightbtn}
                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                } else if (e.type === '7') {
                    console.log(e)
                    return (
                        <Content key={i}>
                            <Section5 
                                backgroundImg={'super-charge-7'}
                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Content>
                    )
                } else if (e.type === '8') {
                    return (
                        <Content key={i}>
                            <Interior 
                                bg={e.backgroundImg} 
                                title={e.title} 
                                leftbtn={e.leftbtn} 
                                rightbtn={e.rightbtn}
                                setPopupType={setPopupType}
                                setOpen={setOpen}
                                
                            />
                        </Content>
                    )
                }
            })}

            <Footer 
                bottom='bottom'
                language={language}
                setLanguage={setLanguage}
            />
            <Popup 
                show={open} 
                PopupType={PopupType}
                setOpen={setOpen}
                language={language}
                setLanguage={setLanguage}
            />
        </Wrapper>
    )
}

export default PowerBank;