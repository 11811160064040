import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    background-image:  ${props => `url('/images/${props.bg}.png'), url('/images/${props.bg}-small.webp')`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    position: relative;
`;

export const Content = styled.div`
    width: 1050px;
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    .title{
        padding-bottom: 15px;
        border-bottom: 1px solid #fff;
        color: #fff;
        font-size: 22px;
        line-height:30px;
        display: flex;
        align-items: flex-end;
        
        .model-item{
            font-size: 18px;
            line-height:22px;
            margin-left:12px;
            color: #fff;
        }
        .model-item:nth-child(1){
            margin-left:30px;
        }
    }

    .detail-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .detail-list{
            width: 150px;
            max-width: 150px;
            .list-title{
                color: #fff;
                font-size:16px;
                margin: 20px 0 30px 0;
            }
            .list-item{
                color: #fff;
                div{
                    color: #fff;
                }
                .item-line{
                    width: 30px;
                    border: 1px solid #fff;
                }
                .item-name{
                    font-size:14px;
                    line-height: 20px;
                    padding-top:10px;
                }
                .item-value{
                    font-size:12px;
                    line-height: 18px;
                    padding-bottom:15px;
                }
            }
        }
    }
`;