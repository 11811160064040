import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;


    .detail{
        height: 100vh;
        width: 20vw;
        min-width:390px;
        padding:60px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .subTitle{
            font-size: 16px;
            line-height: 22px;
        }
        .title{
            font-size: 26px;
            line-height: 38px;
            white-space: pre-wrap;
        }
        .desc{
            max-width:620px;
            width: 100%;
            text-align: left;
            font-size: 12px;
            line-height: 20px;
            margin-top: 20px;
        }
    }

    .detail-bottom{
        max-width:1045px;
        width: 100%;
        height: 205px;
        padding: 40px;
        margin: 0 auto 40px;
        display: flex;
        justify-content: space-between;

        .subTitle{
            font-size: 16px;
            line-height: 22px;
        }
        .title{
            font-size: 26px;
            line-height: 38px;
        }
        .desc{
            max-width:620px;
            width: 100%;
            text-align: left;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .detail-bottom-phone{
        width: 100%;
        padding: 24px 24px 40px;
        .subTitle{
            font-size: 16px;
            line-height: 22px;
        }
        .title{
            font-size: 26px;
            line-height: 38px;
        }
        .desc{
            max-width:620px;
            width: 100%;
            text-align: left;
            font-size: 12px;
            line-height: 20px;
        }
    }
    
`;

export const Content = styled.div`
    background-image:  ${props => `url('/images/${props.bg}.png'), url('/images/${props.bg}-small.webp')`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    width: 80vw;
    height: 100vh;
    position: relative;
    .bg-list{
        width: 270px;
        position: absolute;
        top:200px;
        right: 80px;
        .item{
            margin-bottom:100px;
            .item-title{
                font-size:16px;
                color:#fff;
                margin-bottom:10px
            }
            div{
                font-size:12px;
                color:#fff;
            }
        }
    }
`;