import React from 'react'
import { Wrapper,Content} from './sidenav.style'

import { Link } from 'react-router-dom'

function SideNav({ show, setOpenSideNav, setPopupType, setOpen, language, setLanguage }) {


    const btn_Fn = (type) =>{
        setPopupType(type)
        setOpen(true)
        setOpenSideNav(false)
    }
    return (
        <Wrapper show={show}>
					{language? 
						<Content>
								<Link to='/'><li className='half'>首页</li></Link>
								<Link to='/power-bank'><li className='half'>超级充电</li></Link>
								<Link to='/energy-router'><li className='half'>能源路由器</li></Link>
								<Link to='/energy-storage-solution'><li className='half'>储能方案</li></Link>
								<Link to='/service'><li className='half'>专业服务</li></Link>
								{/* <Link to='/NewAbout'><li>关于大龙新能源</li></Link> */}
								<li onClick={()=> btn_Fn('联络我们')}>技术支持</li>
								{/* <li className='half' >Shop</li> */}
								{/* <Link><li className='half'>账户</li></Link> */}
								{/* <Link to='/login'><li className='half'>账户</li></Link> */}
								{/* <li className='half'>更多</li> */}
						</Content>
						:
						<Content>
								<Link to='/'><li className='half'>Home</li></Link>
								<Link to='/power-bank'><li className='half'>Supercharging</li></Link>
								<Link to='/energy-router'><li className='half'>Energy router</li></Link>
								<Link to='/energy-storage-solution'><li className='half'>Energy storage solutions</li></Link>
								<Link to='/service'><li className='half'>Professional Services</li></Link>
								{/* <Link to='/NewAbout'><li>关于大龙新能源</li></Link> */}
								<li onClick={()=> btn_Fn('联络我们')}>Technical Support</li>
								{/* <li className='half' >Shop</li> */}
								{/* <Link><li className='half'>账户</li></Link> */}
								{/* <Link to='/login'><li className='half'>账户</li></Link> */}
								{/* <li className='half'>更多</li> */}
						</Content>
					}
        </Wrapper>
    )
}

export default SideNav