import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Wrapper, ContentTop, ContentMid, Content } from './section.style'
import '../../baseStyle.css'
import LazyLoad from 'react-lazyload';

function Section({
	title,
	desc,
	backgroundImg,
	link,
	leftbtn,
	rightbtn,
	arrow,
	range,
	range1,
	range2,
	speed,
	speed1,
	speed2,
	hp,
	top,
	top1,
	top2,

	setPopupType,
	setOpen,

	language,
	setLanguage
}) {
	const navigate = useNavigate();

	const btn_Fn = (type) => {
		setPopupType(type)
		setOpen(true)
	}

	return (
		// <LazyLoad>
		<Wrapper bg={backgroundImg} >
			<ContentTop>
				<h1 style={{ color: '#fff' }}>{title}</h1>
				<p style={{ color: '#fff' }}>
					{desc}
					{/* <a href='#'>{link}</a> */}
				</p>
			</ContentTop>
			<div>
				{range &&
					<Content>
						<div className="Info-bar">
							<div className="Specs" >
								<h2>{range} <span style={{ fontSize: '1.3rem', color: '#fff' }}>min</span></h2>
								<p>{range1 ? range1 : language? '充电80%':'Charging 80%'}</p>
								{range2 ? <p>{range2}</p> : ''}
							</div>
							<div className="Specs" >
								<h2>{speed} <span style={{ fontSize: '1.3rem', color: '#fff' }}>㎡</span></h2>
								<p>{speed1 ? speed1 : language? '占地':'Footprint'}</p>
								{speed2 ? <p>{speed2}</p> : ''}
							</div>
							<div className="Specs" >
								<h2>{top} 
									<span style={{ fontSize: '1.3rem', color: '#fff' }}>
										{language? '度':'degree'}
									</span>
								</h2>
								<p title="数据来源：大龙新能源实验室，最终以实际情况为准" style={{ cursor: 'pointer' }}>
									{top1 ? top1 : language? '年节电*':'Annual energy saving*'}
								</p>
								{top2 ? <p>{top2}</p> : ''}
							</div>
							{hp &&
								<div className="Specs hp" >
									<h2>{hp} hp</h2>
									<p>Peak Power</p>
								</div>
							}
							{/* <div className="order_btn">
                                <button> ORDER NOW </button>
                            </div>  */}

						</div>
						{/* {range && <div style={{textAlign:'center',color:'#fff'}}>
                            数据来源：大龙新能源实验室，最终以实际情况为准</div>
                        } */}
					</Content>
				}


				<ContentMid className={arrow ? '' : 'buttons'} >
					{leftbtn &&
						<button className='w220 btn-white' onClick={() => btn_Fn(leftbtn)}>{leftbtn}</button>
					}
					{rightbtn &&
						<button className='w220 btn-vague' onClick={() => { link ? navigate(link) : btn_Fn(rightbtn) }}>{rightbtn}</button>
					}
				</ContentMid>
				{arrow &&
					<div className='arrow'>
						<img src='/images/down-arrow.svg' alt='arrow' loading="lazy" />
					</div>
				}
			</div>
		</Wrapper>
		// </LazyLoad>
	)
}

export default Section
