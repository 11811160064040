import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
    background-image:  ${props => `url('/images/${props.bg}.png'), url('/images/${props.bg}-small.webp')`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        h1{
            margin-top: 40vh;
            margin-bottom: 7vh;
            margin-left: -40vw;
            color: #000;
            font-weight: 500;
            letter-spacing: 0.8px;
            font-size: 2rem;
        }
    }
`;

export const ContentMid = styled.div`
    margin-left: -39vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button{
        outline: none;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 0.6px;
        :hover{
            cursor: pointer;
        }
    }

    .left button{
        background-color: rgba(23,26,32,1);
        color: #000;
        margin-right: 25px;
    }

    @media screen and (max-width: 600px){
        flex-direction: column;
        .left , .right{
            display: flex;
            justify-content: center;
        }
        .left button{
            margin-right: 0;
            margin-bottom: 15px;
        }
        button{
            width: 90%;
            padding: 5px 10px;
        }
    }

    animation: transform 1s;
    animation-timing-function: ease-in;
    @keyframes transform {
        0%{
            opacity: 0;
        }
        50%{
            opacity: 0.3;
        }
        100%{
            opacity: 1;
        }
    }
`;
