import styled from 'styled-components';

export const Wrapper = styled.div`
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
    
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
`;

export const Content = styled.div`
    scroll-snap-align: start;
`;